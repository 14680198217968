import { createSlice } from "@reduxjs/toolkit";

import { logout } from "./sessionSlice";

const stypeSlice = createSlice({
  name: "stype",
  initialState: {},
  reducers: {
    setStypeData: (state, action) => {
      state[action.payload.stype] = action.payload.data;
    }
  },
  extraReducers: {
    [logout.fulfilled]: (state, action) => {
      state = {};
    }
  }
});

export const { setStypeData } = stypeSlice.actions;

export default stypeSlice.reducer;
