import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@material-ui/core";

export function TableGrid({ columnLabels, children }) {
  return (
    <TableContainer>
      <Table size="small" aria-label="customized table">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            {columnLabels.map(label => (
              <TableCell key={label} align="center">
                {label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>{children}</TableBody>
      </Table>
    </TableContainer>
  );
}

export function TableGridRow({ label, children }) {
  return (
    <TableRow key={label}>
      <TableCell component="th" scope="row">
        {label}
      </TableCell>
      {children.map((child, childIndex) => (
        <TableCell key={`${label}-${childIndex}`} align="center" size="small">
          {child}
        </TableCell>
      ))}
    </TableRow>
  );
}
