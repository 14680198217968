import React, { useState, useEffect, useMemo } from "react";
import {
  makeStyles,
  Box,
  Table,
  TableHead,
  TableBody,
  TableFooter,
  TableRow,
  TableCell,
  TableSortLabel,
  TablePagination
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

const useStyles = makeStyles(theme => ({
  skeletonHeader: {
    height: theme.spacing(8)
  }
}));

export default function DataTable({
  data,
  columns,
  id_key,
  pagination,
  defaultSortColumnIndex,
  prePaginationFooterRows,
  postPaginationFooterRows
}) {
  const classes = useStyles();
  // Pagination
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  // Sorting
  const [sortDirection, setSortDirection] = useState("desc");
  const [sortColumn, setSortColumn] = useState(() => {
    return columns[defaultSortColumnIndex || 0];
  });
  const sortedRows = useMemo(() => {
    return [...data];
  }, [data]);
  const displayedRows = sortedRows.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  // Reset to last page if applicable
  useEffect(() => {
    if (page * rowsPerPage > sortedRows.length) {
      setPage(Math.floor(sortedRows.length / rowsPerPage));
    }
  }, [rowsPerPage, sortedRows, page]);

  function updateSort(columnKey) {
    if (sortColumn.key === columnKey) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(columns.find(column => column.key === columnKey));
    }
  }

  function onChangePage(event, page) {
    setPage(page);
  }

  function onChangeRowsPerPage(event, rows) {
    setRowsPerPage(rows.key);
  }

  // Sort
  if (sortColumn.compareFunc) {
    sortedRows.sort(sortColumn.compareFunc);
  } else {
    sortedRows.sort(
      sortColumn.displayFunc
        ? (a, b) => {
            return sortColumn
              .displayFunc(a)
              .localeCompare(sortColumn.displayFunc(b));
          }
        : (a, b) => {
            return a[sortColumn.key].localeCompare(b[sortColumn.key]);
          }
    );
  }

  if (sortDirection === "desc") {
    sortedRows.reverse();
  }

  if (data.length > 0) {
    return (
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            {columns.map(column => (
              <TableCell align={column.align || "left"} key={column.key}>
                <TableSortLabel
                  active={sortColumn.key === column.key}
                  direction={sortDirection}
                  onClick={() => updateSort(column.key)}
                >
                  {column.name}
                </TableSortLabel>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {displayedRows.map(row => (
            <TableRow hover key={row[id_key]}>
              {columns.map(column => (
                <TableCell key={column.key} align={column.align || "left"}>
                  {column.displayFunc
                    ? column.displayFunc(row)
                    : row[column.key].toString()}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          {prePaginationFooterRows
            ? prePaginationFooterRows.map(rowFunc =>
                rowFunc(sortedRows, displayedRows)
              )
            : null}
          <TableRow>
            <TablePagination
              colSpan={columns.length}
              page={page}
              rowsPerPage={rowsPerPage}
              count={sortedRows.length}
              onChangePage={onChangePage}
              onChangeRowsPerPage={onChangeRowsPerPage}
            />
          </TableRow>
          {postPaginationFooterRows
            ? postPaginationFooterRows.map(rowFunc =>
                rowFunc(sortedRows, displayedRows)
              )
            : null}
        </TableFooter>
      </Table>
    );
  } else {
    return (
      <Box>
        <Skeleton className={classes.skeletonHeader} />
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </Box>
    );
  }
}
