import React from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    paddingTop: "10px",
    paddingBottom: "10px"
  }
});

export default function Media(props) {
  const classes = useStyles();

  const getMediaType = props => {
    switch (props.media_type_id) {
      case 1:
        return <Video {...props} />;
      case 2:
        return <Picture {...props} />;
      default:
        return <div />;
    }
  };

  return (
    <div className={classes.root}>
      {getMediaType(props)}
      <Typography gutterBottom>{props.caption}</Typography>
    </div>
  );
}

function Video({ url, caption, attributes }) {
  const html = `<iframe src="${url}" title="${caption}" style="max-width:100%; ${attributes}" frameBorder="0" allow="fullscreen"/>`;
  return <div dangerouslySetInnerHTML={{ __html: html }} />;
}

function Picture({ url, caption, attributes }) {
  const html = `<img src="${url}" alt="${caption}" style="max-width:100%; ${attributes}" />`;
  return <div dangerouslySetInnerHTML={{ __html: html }} />;
}
