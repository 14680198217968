import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchUserQueues } from "./reducers/queueSlice";
import { BrowserRouter as Router } from "react-router-dom";
import { Switch, Route, Redirect, useRouteMatch } from "react-router-dom";

import AppNav from "./AppNav";
import Login from "./Login";
import Signup from "./Signup";
import Payments from "./Payments";
import { RequestReset, Reset } from "./Reset";
import Radiology from "./Radiology";
import Queues from "./Queues";
import Studies from "./Studies";
import ConfirmEmail from "./Signup/ConfirmEmail";
import Registration from "./Registration";
import HomePage from "./Components/HomePage";
import DirectQueue from "./Queues/DirectQueue";

import InstrumentPreview from "./Instrument/Preview";
import InstrumentDebugger from "./Instrument/Debugger";
import { ChangeEmail } from "./ChangeEmail";
import SessionCheck from "./Components/SessionCheck";

function App() {
  const { authenticated, idle } = useSelector(state => state?.session);

  useEffect(() => {
    localStorage.setItem("lastActiveTime", new Date().getTime());
  }, []);

  return (
    <Router>
      {authenticated || idle ? <AuthenticatedApp /> : <UnauthenticatedApp />}
    </Router>
  );
}

function AuthenticatedApp() {
  const dispatch = useDispatch();
  const { emailConfirmed } = useSelector(state => state?.session);

  useEffect(() => {
    if (emailConfirmed) {
      dispatch(fetchUserQueues());
    }
  }, [dispatch, emailConfirmed]);

  const fullInterfaceStype =
    useRouteMatch({
      path: ["/confirm/:code/:stype"],
      exact: true
    }) && !emailConfirmed;

  const fullInterface =
    useRouteMatch({
      path: [
        "/queues/:id",
        "/instrument/:id",
        "/registration/:stype?",
        "/login/:stype",
        "/signup/:stype"
      ],
      exact: true
    }) || fullInterfaceStype
      ? false
      : true;

  return (
    <AppNav fullInterface={fullInterface}>
      <SessionCheck />

      <ConfirmEmail>
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route path="/instrument/:id?" component={InstrumentDebugger} />
          <Route path="/queues/:id?" component={Queues} />
          {/* <Route path="/calendar">
            <div>calendar</div>
          </Route> */}
          <Route path="/payments" component={Payments} />
          <Route path="/radiology" component={Radiology} />
          <Route path="/studies" component={Studies} />
          <Route path="/change-email" component={ChangeEmail} />

          <Route path="/registration/:stype?" component={Registration} />
          <Redirect from="/login/:stype" to="/registration/:stype" />
          <Redirect from="/signup/:stype" to="/registration/:stype" />

          <Redirect to="/" />
        </Switch>
      </ConfirmEmail>
    </AppNav>
  );
}

function UnauthenticatedApp() {
  return (
    <Switch>
      <Route path="/preview/instrument/:id" component={InstrumentPreview} />
      <Route path="/login/:stype?" component={Login} />
      <Route path="/signup/:stype?" component={Signup} />
      <Route path="/request-reset/:stype?" component={RequestReset} />
      <Route path="/reset/:code/:stype?" component={Reset} />
      <Route path="/confirm/:code/:stype?" component={Login} />
      <Route path="/dq/:code" component={DirectQueue} />
      <Route path="/">
        <Redirect to={{ pathname: "/login" }} />
      </Route>
    </Switch>
  );
}

export default App;
