import React from "react";
import {
  ThemeProvider as MUIThemeProvider,
  createMuiTheme
} from "@material-ui/core/styles";

// coins logo colors
// dark blue #003c52
// light blue #3e6e87
// orange #f05a28
// teal #71c3bf
// pea green #d6dd23

const coinsTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#003c52"
    }
    // secondary: {
    //   main: "#71c3bf"
    // }
  },
  drawerWidth: 240,
  viewBackgroundColor: "#f5f5f5"
});

export function ThemeProvider(props) {
  return <MUIThemeProvider theme={coinsTheme} {...props} />;
}
