import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

import DangerousTypography from "./DangerousTypography";

const useStyles = makeStyles(theme => ({
  container: {
    maxHeight: "75vh",
    paddingBottom: theme.spacing(5) // Stupid hack to prevent vertical scroll bar
  },
  table: {
    tableLayout: "fixed",
    "& tbody tr th:first-child": {
      backgroundColor: "white",
      position: "sticky",
      left: "0px",
      zIndex: 200
    },
    "& thead tr th:not(:first-child)": {
      backgroundColor: "white",
      position: "sticky",
      top: "0px",
      zIndex: 150,
      borderBottom: "1px solid lightgrey"
    },
    "& thead tr th": {
      boxShadow: "0 -2px white"
    },
    "& th, & td": { padding: theme.spacing(1) }
  },
  cornerCell: {
    backgroundColor: "white",
    position: "sticky",
    left: "0px",
    top: "0px",
    zIndex: 300
  },
  colLabelHeader: {},
  colCheckHeader: {
    wordWrap: "break-word"
  },
  colSkipHeader: {
    width: "9em"
  }
}));

export default function LikertGrid({ columnLabels, children }) {
  const classes = useStyles();

  let extraTableWidth = {};
  let colLabelWidth = { width: "35%" };
  if (columnLabels.length > 10) {
    extraTableWidth = {
      width:
        // lots of columns, so push out the table
        "calc(100% + " + ((columnLabels.length - 10) * 20).toString() + "%)"
    };
    colLabelWidth = {
      /* we also need to adjust the width because the default 35% looks much larger when the table is pushed out
       * So we need the width to reduce in size with each additional column, but never go below 0,
       */
      width: `calc(35% * (8 / ${columnLabels.length}))`
    };
  }

  return (
    <TableContainer className={classes.container}>
      <Table size="small" className={classes.table} style={extraTableWidth}>
        <col className={classes.colLabelHeader} style={colLabelWidth} />
        {columnLabels.map(label => {
          return <col key={label} className={classes.colCheckHeader} />;
        })}
        <col className={classes.colSkipHeader} />
        <TableHead>
          <TableRow>
            <TableCell
              component="th"
              className={classes.cornerCell}
            ></TableCell>
            {columnLabels.map(label => {
              return (
                <TableCell
                  key={label}
                  align="center"
                  component="th"
                  className={classes.colCheckHeader}
                >
                  <DangerousTypography>{label}</DangerousTypography>
                </TableCell>
              );
            })}
            <TableCell component="th" />
          </TableRow>
        </TableHead>
        <TableBody>{children}</TableBody>
      </Table>
    </TableContainer>
  );
}
