import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import doApiRequest from "../ApiClient";
import { logout } from "./sessionSlice";
import { evaluateRules } from "../Instrument/FormulaParser";

export const persistResponsesToDBMiddleware = store => next => action => {
  let result = next(action);

  const saveEvents = [
    "assessment/saveResponse",
    "assessment/toggleUserSkipped",
    "assessment/toggleConditionalySkipped"
  ];
  if (saveEvents.includes(action.type)) {
    const {
      assessmentId,
      questionId,
      loopInstance = 0,
      questionIds = []
    } = action.payload;

    if (assessmentId === -1) {
      // SA Preview
      return result;
    }

    if (questionId) {
      // single change
      const response =
        store.getState()?.assessment?.[assessmentId]?.responses?.[questionId]?.[
          loopInstance
        ] || {};
      const questions = { [questionId]: { [loopInstance]: response } };
      store.dispatch(saveResponseInDB({ assessmentId, questions }));
    } else if (questionIds.length > 0) {
      // batch change
      const questions = questionIds.reduce(
        (a, questionId) => ({
          ...a,
          [questionId]: {
            [loopInstance]:
              store.getState()?.assessment?.[assessmentId]?.responses?.[
                questionId
              ]?.[loopInstance] || {}
          }
        }),
        {}
      );
      store.dispatch(saveResponseInDB({ assessmentId, questions }));
    }
  }

  return result;
};

export const evaluateConditionalRulesMiddleware = store => next => action => {
  let result = next(action);

  if (action.type === "assessment/saveResponse") {
    const { assessmentId, questionId, loopInstance } = action.payload;
    store.dispatch(
      evaluateConditionalRules({ assessmentId, questionId, loopInstance })
    );
  }

  return result;
};

export const initAssessment = createAsyncThunk(
  "assessment/initAssessment",
  async (
    {
      queueId,
      queueInstrumentId,
      instrumentId,
      assessmentId: existingAssessmentId,
      startAtQuestion: initialStartAtQuestion
    },
    { getState, dispatch, rejectWithValue }
  ) => {
    if (!queueId || !queueInstrumentId || !instrumentId) {
      return rejectWithValue("missing required parameters");
    }

    let assessmentId = existingAssessmentId || false;
    if (!assessmentId) {
      const { payload, error } = await dispatch(
        startAssessment({ queueId, queueInstrumentId })
      );
      if (error) {
        return rejectWithValue(payload);
      }
      assessmentId = payload;
    }

    await dispatch(fetchAssessmentResponses({ instrumentId, assessmentId }));

    await dispatch(evaluateConditionalRules({ assessmentId }));

    let startAtQuestion = initialStartAtQuestion || false; // SA Preview
    if (!startAtQuestion) {
      const { payload } = await dispatch(
        determineStartingQuestion({ assessmentId, instrumentId })
      );
      startAtQuestion = payload;
    }

    return { assessmentId, startAtQuestion };
  }
);

export const startAssessment = createAsyncThunk(
  "assessment/startAssessment",
  async ({ queueId, queueInstrumentId }, { rejectWithValue }) => {
    const response = await doApiRequest(
      `asmt/start-assessment/${queueInstrumentId}`,
      { method: "POST" }
    );

    const assessmentId = response?.data?.assessment_id;
    if (!Number.isInteger(assessmentId)) {
      return rejectWithValue({
        error: response?.data?.errors[0] || "invalid assessmentId",
        response
      });
    }

    return assessmentId;
  }
);

export const fetchAssessmentResponses = createAsyncThunk(
  "assessment/fetchAssessmentResponses",
  async ({ instrumentId, assessmentId }, { rejectWithValue }) => {
    const response = await doApiRequest(`asmt/assessment/${assessmentId}/`, {
      method: "GET"
    });

    const responses = response?.data;
    if (!("questions" in responses) || !("meta" in responses)) {
      return rejectWithValue(responses);
    }

    return {
      instrumentId,
      assessmentId,
      responses: responses.questions,
      meta: responses.meta
    };
  }
);

export const completeAssessment = createAsyncThunk(
  "assessment/completeAssessment",
  async (
    { queueId, assessmentId, noCheck = false },
    { rejectWithValue, getState }
  ) => {
    const { assessment } = getState();
    const savedInDB = assessment?.[assessmentId]?.savedInDB || false;

    if (!noCheck && !savedInDB) {
      return rejectWithValue({ error: "unsaved responses" });
    }

    const response = await doApiRequest(
      `asmt/complete-assessment/${assessmentId}`,
      { method: "POST" }
    );

    if (response?.data !== "success") {
      return rejectWithValue({
        error: "error saving responses",
        queueId,
        assessmentId,
        response
      });
    }

    return { queueId, assessmentId };
  }
);

export const evaluateConditionalRules = createAsyncThunk(
  "assessment/evaluateConditionalRules",
  async (
    { assessmentId, questionId: currentQuestionId },
    { getState, dispatch }
  ) => {
    const { instrument, assessment } = getState();
    const { instrumentId, ...assessmentState } =
      assessment?.[assessmentId] || {};
    const { question_ids: questionIds, jumps, questions } =
      instrument?.[instrumentId]?.object || {};

    // evaluate rules
    const { skippedQIDs, loopTriggered, rulesTriggered } = evaluateRules(
      assessmentState,
      {
        questionIds,
        questions,
        jumps
      }
    );

    // determin which questionIds changed
    const qIdsToToggle = questionIds.filter(questionId => {
      const loopInstance = 0;
      const skipped =
        assessmentState?.responses?.[questionId]?.[loopInstance]
          ?.conditionally_skipped || false;
      const shouldBeSkipped = skippedQIDs.includes(questionId);
      return skipped !== shouldBeSkipped;
    });

    if (qIdsToToggle.length > 0) {
      dispatch(
        toggleConditionalySkipped({
          assessmentId,
          questionIds: qIdsToToggle
        })
      );
    }

    // apply loop
    if (loopTriggered) {
      const { source, target } = loopTriggered;
      // TODO: impliment looping
      console.log(`loop triggered source ${source} target ${target}`);
    }

    return { assessmentId, skippedQIDs, loopTriggered, rulesTriggered };
  }
);

export const determineStartingQuestion = createAsyncThunk(
  "assessment/determineStartingQuestion",
  async ({ assessmentId, instrumentId }, { getState }) => {
    const { assessment, instrument } = getState();
    const { question_ids: questionIds, questions } =
      instrument?.[instrumentId]?.object || {};

    const { questionId, autoComplete } = questionIds.reduce(
      (acc, questionId, index, array) => {
        const { is_sa_hidden: hidden = false, autopop_qid: autoPopQID } =
          questions?.[questionId] || {};
        const {
          instances,
          conditionally_skipped: conditionallySkipped = false,
          user_skipped: userSkipped = false
        } = assessment?.[assessmentId]?.responses?.[questionId]?.[0] || {};

        const autoPop = Boolean(autoPopQID);
        const hasResponse =
          Object.keys(instances?.[1] || {}).length > 0 || userSkipped;

        // if the questionId is set we've already found the starting questionId
        if (acc.questionId) {
          return acc;
        }

        // auto populated response
        if (!hidden && autoPop) {
          if (!acc.autoPopQuestionId) {
            acc.autoPopQuestionId = questionId;
          }
        }
        // response is required
        else if (!hidden && !conditionallySkipped && !hasResponse) {
          if (!acc.autoPopQuestionId) {
            acc.questionId = questionId;
          } else {
            acc.questionId = acc.autoPopQuestionId;
          }
        }
        // response is valid
        else if (!autoPop && !hidden && hasResponse) {
          acc.autoPopQuestionId = null;
        }

        // don't auto complete if there are editable questions
        if (!hidden && !conditionallySkipped) {
          acc.autoComplete = false;

          if (!acc.firstEditableQuestionId) {
            acc.firstEditableQuestionId = questionId;
          }
        }

        // if on the last iteration we haven't found a questionId
        if (index === array.length - 1) {
          if (acc.autoPopQuestionId) {
            acc.questionId = acc.autoPopQuestionId;
          } else {
            acc.questionId = acc.firstEditableQuestionId;
          }
        }

        return acc;
      },
      {
        questionId: null,
        autoPopQuestionId: null,
        firstEditableQuestionId: null,
        autoComplete: true
      }
    );

    // return next question
    return {
      questionId: autoComplete ? "END" : questionId,
      instance: 1 // always start at instance 1
    };
  }
);

export const evaluateMissingResponses = createAsyncThunk(
  "assessment/evaluateMissingResponses",
  async (
    { assessmentId, loopInstance = 0, questionIds = [] },
    { getState }
  ) => {
    const { assessment } = getState();

    return questionIds.filter(questionId => {
      const {
        conditionally_skipped: conditionallySkipped,
        user_skipped: userSkipped,
        instances
      } =
        assessment?.[assessmentId]?.responses?.[questionId]?.[loopInstance] ||
        {};

      const hasResponse = Object.keys(instances?.[1] || {}).length >= 1;

      return (
        !hasResponse &&
        !conditionallySkipped &&
        !userSkipped &&
        questionId.indexOf("~text_only_section~") < 0
      );
    });
  }
);

export const saveResponseInDB = createAsyncThunk(
  "assessment/saveResponseInDB",
  async ({ assessmentId, questions }, { rejectWithValue }) => {
    const result = await doApiRequest(`asmt/assessment/${assessmentId}/`, {
      method: "POST",
      body: JSON.stringify({
        questions
      })
    });

    if (result?.data !== "success") {
      return rejectWithValue(result?.data);
    }

    return {
      assessmentId,
      questionIds: Object.keys(questions),
      savedInDB: true
    };
  }
);

export const assessmentSlice = createSlice({
  name: "assessment",
  initialState: {
    // [assessmentId]: {
    //   meta: {
    //      age:
    //      gender:
    //      subject_type_id:
    //   },
    //   ruleEvaluation: {
    //      skippedQIDs: [],
    //      loopTriggered: false || { source, target },
    //      rulesTriggered:[]
    //   },
    //   responses: {
    //     {
    //       [questionId]: {
    //         [loopInstance]: {
    //           savedInDB: bool
    //           conditionally_skipped: bool
    //           instances: {
    //             [instanceId]: {
    //               value: ""
    //             }
    //           }
    //         }
    //       },
    //        [questionId]: {
    //           [loopInstance]: {
    //             savedInDB: bool
    //             conditionally_skipped: bool
    //             instances: {
    //               [instanceId]: {
    //                 [responseId]: {
    //                   value: ""
    //                   description: ""
    //                 }
    //               }
    //             }
    //           }
    //         }
    //       }
    //     }
    //   },
    //   savedInDB: bool
    // }
  },
  reducers: {
    saveResponse(state, action) {
      const {
        assessmentId,
        questionId,
        loopInstance,
        instanceId,
        response
      } = action.payload;

      // save valid response to state
      return {
        ...state,
        [assessmentId]: {
          ...state[assessmentId],
          responses: {
            ...state?.[assessmentId]?.responses,
            [questionId]: {
              ...state?.[assessmentId]?.responses?.[questionId],
              [loopInstance]: {
                ...state?.[assessmentId]?.responses?.[questionId]?.[
                  loopInstance
                ],
                instances: {
                  ...state?.[assessmentId]?.responses?.[questionId]?.[
                    loopInstance
                  ]?.instances,
                  [instanceId]: response
                },
                savedInDB: false
              }
            }
          },
          savedInDB: false
        }
      };
    },
    toggleUserSkipped(state, action) {
      const { assessmentId, loopInstance, questionId } = action.payload;
      return {
        ...state,
        [assessmentId]: {
          ...state[assessmentId],
          responses: {
            ...state?.[assessmentId]?.responses,
            [questionId]: {
              ...state?.[assessmentId]?.responses?.[questionId],
              [loopInstance]: {
                ...state?.[assessmentId]?.responses?.[questionId]?.[
                  loopInstance
                ],
                user_skipped: !state?.[assessmentId]?.responses?.[questionId]?.[
                  loopInstance
                ]?.user_skipped,
                savedInDB: false
              }
            }
          },
          savedInDB: false
        }
      };
    },
    toggleConditionalySkipped(state, action) {
      const { assessmentId, loopInstance = 0, questionIds } = action.payload;
      if (state?.[assessmentId]?.responses) {
        questionIds.forEach(questionId => {
          // check if question doesn't exist yet
          if (!state[assessmentId].responses?.[questionId]) {
            state[assessmentId].responses[questionId] = {};
          }

          // check if question loopInstance doesn't exist yet
          if (!state[assessmentId].responses?.[questionId]?.[loopInstance]) {
            state[assessmentId].responses[questionId][loopInstance] = {};
          }

          // get current con. skipped status
          const { conditionally_skipped = false } = state[
            assessmentId
          ].responses[questionId][loopInstance];

          // toggle skipped status
          state[assessmentId].responses[questionId][
            loopInstance
          ].conditionally_skipped = !conditionally_skipped;

          // set savedInDB to false
          state[assessmentId].responses[questionId][
            loopInstance
          ].savedInDB = false;
          state[assessmentId].savedInDB = false;
        });
      }
    }
  },
  extraReducers: {
    [fetchAssessmentResponses.fulfilled]: (state, action) => {
      const { instrumentId, assessmentId, responses, meta } = action.payload;
      return {
        ...state,
        [assessmentId]: {
          ...state[assessmentId],
          instrumentId,
          responses,
          meta,
          savedInDB: true
        }
      };
    },
    [evaluateConditionalRules.fulfilled]: (state, action) => {
      const {
        assessmentId,
        skippedQIDs,
        loopTriggered,
        rulesTriggered
      } = action.payload;
      return {
        ...state,
        [assessmentId]: {
          ...state[assessmentId],
          ruleEvaluation: { skippedQIDs, loopTriggered, rulesTriggered }
        }
      };
    },
    [completeAssessment.fulfilled]: (state, action) => {
      const { assessmentId } = action.payload;
      delete state[assessmentId];
    },
    [saveResponseInDB.fulfilled]: (state, action) => {
      const {
        assessmentId,
        questionIds,
        loopInstance = 0,
        savedInDB
      } = action.payload;

      if (state?.[assessmentId]?.responses) {
        // update the saved status for each questionId
        questionIds.forEach(questionId => {
          if (state[assessmentId].responses?.[questionId]?.[loopInstance]) {
            state[assessmentId].responses[questionId][
              loopInstance
            ].savedInDB = savedInDB;
          }
        });

        // update assessment level saved status
        state[assessmentId].savedInDB = Object.values(
          state[assessmentId].responses
        ).every(response => response?.[loopInstance]?.savedInDB);
      }
    },
    [saveResponseInDB.rejected]: (state, action) => {
      // todo: add retry logic
      console.log(action.payload);
    },
    [logout.fulfilled]: (state, action) => {
      state = {};
    }
  }
});

export const {
  saveResponse,
  toggleUserSkipped,
  toggleConditionalySkipped
} = assessmentSlice.actions;

export default assessmentSlice.reducer;
