import { parser } from "./Parser/parser.js";

export function initParser({ questions, responses, meta, loopInstance = 0 }) {
  parser.yy = {
    sumOverAllInstances: questionId => {
      // not yet supported
      return false;
    },
    reverseScore: input => {
      // not yet supported
      return false;
    },
    skippedQIDs: [],
    lookupQIDVal: questionId => {
      const instance = 1; // rule formulas only support first instance
      const { question_type: questionType } = questions?.[questionId] || {};
      const { value, ...allResponses } =
        responses?.[questionId]?.[loopInstance]?.instances?.[instance] || {};

      // single response
      if (value) {
        // check for date string
        if (/^\s*[0-9]{2}\/[0-9]{2}\/[0-9]{4}\s*$/.test(value)) {
          return Date.parse(value);
        }
        return value;
      }

      // multi response (responseIds)
      const responseIds = Object.keys(allResponses);
      if (responseIds.length !== 0) {
        if (questionType === "CANNED_RESPONSE_CHECKBOX") {
          // checkbox type questions can have mutliple responses selected
          return Object.values(allResponses)
            .sort((a, b) => a.sort_order - b.sort_order)
            .reduce((a, { value, sort_order }) => {
              if (value != null) {
                a.push(`${value}-${sort_order}`);
              }
              return a;
            }, [])
            .join(",");
        } else if (responseIds.length === 1) {
          // all other responseId type questions only allow a single response
          return allResponses[responseIds[0]]?.value;
        }
      }
    },
    getCounterValue: () => loopInstance + 1,
    getSubjectGender: () => meta.gender,
    getSubjectAgeAtAsmt: () => meta.age,
    getSubjectTypeId: () => meta.subject_type_id
  };

  return parser;
}

export function evaluateRules(assessmentState, instrument) {
  const { questionIds, questions, jumps } = instrument;
  const parser = initParser({ ...assessmentState, questions });

  let rulesTriggered = [];
  let loopTriggered = false;
  for (const questionId of questionIds) {
    if (!parser.yy.skippedQIDs.includes(questionId) && questionId in jumps) {
      const ruleTriggered = jumps[questionId].find(({ formula }) => {
        try {
          return parser.parse(formula);
        } catch (error) {
          return false;
        }
      });

      if (ruleTriggered) {
        const { question_jump_id, target_qid } = ruleTriggered;

        // keep track of triggered rules
        rulesTriggered.push(question_jump_id);

        // calculate
        const currentIndex = questionIds.indexOf(questionId);
        const targetIndex =
          target_qid === "END"
            ? questionIds.length // skip all remaining QIDs
            : questionIds.indexOf(target_qid); // skip up to target QID

        if (targetIndex > currentIndex + 1) {
          // skip forward
          parser.yy.skippedQIDs.push(
            ...questionIds.slice(currentIndex + 1, targetIndex)
          );
        } else if (targetIndex <= currentIndex) {
          // loop back (DISABLED until loops are fully supported)
          // loopTriggered = { source: questionId, target: target_qid };
          // break;
        }
      }
    }
  }

  return { skippedQIDs: parser.yy.skippedQIDs, loopTriggered, rulesTriggered };
}

export default evaluateRules;
