import React, { useState } from "react";
import { useForm } from "react-hook-form";
import doApiRequest from "../ApiClient";
import * as yup from "yup";
import {
  CssBaseline,
  TextField,
  Grid,
  Typography,
  Container,
  Card
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Alert } from "@material-ui/lab";
import SubmitButton from "../Reset/SubmitButton";
import { useSelector, useDispatch } from "react-redux";
import { setEmailConfirmed, setEmail } from "../reducers/sessionSlice";

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3)
  },
  errorText: {
    display: "inline"
  },
  header: {
    paddingBottom: theme.spacing(4)
  },
  tableCard: {
    padding: theme.spacing(4)
  }
}));

export default function ChangeEmail() {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const { email } = useSelector(state => state?.session);
  const dispatch = useDispatch();

  const changeEmailSchema = yup.object().shape({
    email: yup
      .string()
      .email("Invalid email")
      .required("Email is required"),
    confirmemail: yup
      .string()
      .required("Confirm Email is required")
      .oneOf([yup.ref("email")], "Emails must match")
  });

  const { register, handleSubmit, errors } = useForm({
    validationSchema: changeEmailSchema
  });

  async function changeEmail({ email }) {
    setLoading(true);
    const { error, data } = await doApiRequest(`pas/change-email/`, {
      method: "POST",
      body: JSON.stringify({ email })
    });
    if (error) {
      setError(data?.errors);
      setSuccess(false);
    } else {
      setError(false);
      setSuccess(true);
      showEmailNotConfirmedMessage();
      dispatch(setEmail(email));
    }
    setLoading(false);
  }

  const showEmailNotConfirmedMessage = () => {
    const setEmailConfirmedState = () => {
      dispatch(setEmailConfirmed(false));
      document
        .getElementById("left-nav")
        .removeEventListener("mousedown", setEmailConfirmedState);
    };
    document
      .getElementById("left-nav")
      .addEventListener("mousedown", setEmailConfirmedState);
  };

  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline />
      <div className={classes.paper}>
        <Typography variant="h4" className={classes.header} align="center">
          Change Email
        </Typography>
        <Card className={classes.tableCard}>
          <Typography>Current Email Address: {email}</Typography>
          <form
            className={classes.form}
            noValidate
            onSubmit={handleSubmit(payload => changeEmail(payload))}
          >
            {!success ? (
              <>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      type="email"
                      variant="outlined"
                      fullWidth
                      id="email"
                      label="New Email Address"
                      name="email"
                      inputRef={register}
                      error={errors?.email ? true : false}
                      helperText={errors?.email?.message}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      type="email"
                      variant="outlined"
                      fullWidth
                      id="confirmemail"
                      label="Confirm New Email Address"
                      name="confirmemail"
                      inputRef={register}
                      error={errors?.confirmemail ? true : false}
                      helperText={errors?.confirmemail?.message}
                    />
                  </Grid>
                </Grid>
                <SubmitButton loading={loading}>Change</SubmitButton>
              </>
            ) : (
              <Alert severity="success" className={classes.alert}>
                <span className={classes.errorText}>
                  Your email address has been successfully changed. We sent you
                  a confirmation email. Please click the link to confirm your
                  email address.
                </span>
              </Alert>
            )}
            {error ? (
              <Alert severity="error" className={classes.alert}>
                <span className={classes.errorText}>{error}</span>
              </Alert>
            ) : null}
          </form>
        </Card>
      </div>
    </Container>
  );
}
