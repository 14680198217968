import React, { useContext, forwardRef } from "react";
import { AssessmentContext } from "../../../AppContext/AssessmentContext";
import useCannedResponseState from "./useCannedResponseStateHook";
import { FormControl, Select, MenuItem, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    padding: "5px 20px",
    textAlign: "left"
  },
  inTable: {
    padding: "6px 0px",
    textAlign: "left"
  }
}));

function CannedResponseSelect(props, ref) {
  const classes = useStyles();
  const { showErrors } = useContext(AssessmentContext);
  const { question_id: questionId, parentType } = props;
  const {
    isValidResponse,
    state,
    userSkipped,
    selectedResponses,
    setSelected
  } = useCannedResponseState(props);

  // if the component is rendered within a table we may want to flag the input component
  const inTable = parentType === "TABLE";
  const flagError = inTable && showErrors && !isValidResponse;
  const fullWidth = inTable;

  const [selectedValue] = selectedResponses?.[0] || [""];

  return (
    <div className={inTable ? classes.inTable : classes.root}>
      <FormControl
        variant="outlined"
        size="small"
        fullWidth={fullWidth}
        error={flagError}
      >
        <Select
          ref={ref}
          labelId={questionId}
          id={questionId}
          value={userSkipped ? "" : selectedValue}
          onChange={e => setSelected(e.target.value)}
          align="left"
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {Object.entries(state)
            .sort(([, { sortOrder: a }], [, { sortOrder: b }]) => a - b)
            .map(([responseId, { label }]) => (
              <MenuItem key={responseId} value={responseId} dense>
                {label}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </div>
  );
}

export default forwardRef(CannedResponseSelect);
