import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import doApiRequest from "../ApiClient";
import { setStypeData } from "../reducers/stypeSlice";

export default function useStypeData(stype) {
  const dispatch = useDispatch();

  const stypeData = useSelector(state => state?.stype[stype]);

  useEffect(() => {
    if (stype && !stypeData) {
      doApiRequest(`pas/stype/${stype}/`).then(({ data, error }) => {
        if (error) {
          dispatch(setStypeData({ stype, data: data.errors }));
        } else {
          dispatch(
            setStypeData({
              stype,
              data: {
                label: data.label,
                showFutureContact: data.enable_future_contact_in_p2,
                showDataSharing: data.enable_data_sharing_in_p2,
                raceAndEthnicity: data.race_and_ethnicity,
                status_id: data.status_id
              }
            })
          );
        }
      });
    }
  }, [stype, stypeData, dispatch]);

  return stypeData;
}
