import React, { useEffect, useState, useCallback } from "react";
import {
  Route,
  Switch,
  useParams,
  useHistory,
  useRouteMatch
} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setEmail, setEmailConfirmed } from "../reducers/sessionSlice";
import { makeStyles } from "@material-ui/core/styles";
import {
  Container,
  CssBaseline,
  Paper,
  Typography,
  LinearProgress,
  Link,
  Snackbar
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import doApiRequest from "../ApiClient";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: theme.spacing(10)
  },
  paper: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "40px"
  },
  loading: {
    width: "100%"
  }
}));

export default function ConfirmEmail({ children }) {
  const dispatch = useDispatch();

  const { emailConfirmed } = useSelector(state => state?.session);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [showNotification, setShowNotification] = useState(false);

  useEffect(() => {
    if (!emailConfirmed) {
      doApiRequest("pas/confirm-check/").then(({ error, data }) => {
        setLoading(false);
        if (error) {
          setError("There was an error validating your account.");
        } else {
          dispatch(setEmailConfirmed(data.status === "confirmed"));
          if (data.status === "confirmed") {
            dispatch(setEmail(data.email));
          }
        }
      });
    } else {
      setLoading(false);
    }
  }, [emailConfirmed, dispatch]);

  if (error) {
    return <ConfirmBox title="Error" message={error} />;
  }

  if (loading) {
    return <ConfirmBox loading={true} />;
  }

  return (
    <Switch>
      <Route path="/confirm/:code/:stype?">
        <DoConfirm
          emailConfirmed={emailConfirmed}
          setError={setError}
          setShowNotification={setShowNotification}
        />
      </Route>

      <Route path="/">
        {emailConfirmed ? children : <Unconfirmed setError={setError} />}
        {showNotification && <ConfirmNotification />}
      </Route>
    </Switch>
  );
}

function DoConfirm({ emailConfirmed, setError, setShowNotification }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { code, stype } = useParams();

  useEffect(() => {
    if (!emailConfirmed) {
      doApiRequest("pas/confirm/", {
        method: "POST",
        body: JSON.stringify({ code })
      }).then(({ error, data }) => {
        if (error) {
          if (data.errors && data.errors[0] !== "confirmed") {
            setError("There was an error confirming your email address.");
            console.error(data.errors);
          }
        } else {
          dispatch(setEmailConfirmed(true));
          setShowNotification(true);
          if (stype) {
            history.push(`/registration/${stype}`);
          } else {
            history.push("/");
          }
        }
      });
    }
  }, [
    emailConfirmed,
    code,
    setError,
    setShowNotification,
    stype,
    history,
    dispatch
  ]);

  if (emailConfirmed) {
    return (
      <ConfirmBox
        title="Already Confirmed"
        message="The email address for this account has already been confirmed."
      />
    );
  }

  return <ConfirmBox title="Confirming..." loading={true} />;
}

function Unconfirmed({ setError }) {
  const [resent, setResent] = useState(false);
  const stype = useRouteMatch({
    path: ["/registration/:stype", "/login/:stype", "/signup/:stype"],
    exact: true
  })?.params?.stype;

  const resendEmailConfirmation = useCallback(() => {
    doApiRequest("pas/confirm-resend/" + (stype ? `?stype=${stype}` : "")).then(
      ({ error }) => {
        if (error) {
          setError("There was an error resending the email confirmation link.");
        } else {
          setResent(true);
        }
      }
    );
  }, [stype, setError]);

  if (resent) {
    return (
      <ConfirmBox
        title="Confirmation Email Resent"
        message="We have sent an email with a confirmation link to your email address."
      />
    );
  }

  return (
    <ConfirmBox
      title="Confirm Email"
      message={
        <>
          We sent you a confirmation email. Please click the link to confirm
          your email address. Or{" "}
          <Link color="primary" onClick={resendEmailConfirmation}>
            Click here{" "}
          </Link>
          to resend the email.
        </>
      }
    />
  );
}

function ConfirmBox({ title, message, loading = false }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Container component="main" maxWidth="sm">
        <Typography component="h1" variant="h5">
          {title}
        </Typography>
        <Paper className={classes.paper}>
          <Typography gutterBottom style={{ width: "100%" }}>
            {message}
          </Typography>
          {loading && <LinearProgress className={classes.loading} />}
        </Paper>
      </Container>
    </div>
  );
}

function ConfirmNotification() {
  const [show, setShow] = useState(true);

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left"
      }}
      open={show}
    >
      <MuiAlert
        elevation={6}
        variant="filled"
        onClose={() => setShow(false)}
        severity="info"
      >
        Thank you for confirming your account!
      </MuiAlert>
    </Snackbar>
  );
}
