import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import {
  Button,
  DialogActions,
  TextField,
  makeStyles
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import * as yup from "yup";
import Captcha from "../Components/Captcha";

import SubmitButton from "../Components/SubmitButton";
import doApiRequest from "../ApiClient";
import { login, logout, checkAuth } from "../reducers/sessionSlice";

const useStyles = makeStyles(theme => ({
  submit: {
    margin: theme.spacing(2, 0, 2)
  },
  error: { marginTop: theme.spacing(1), marginBottom: theme.spacing(2) }
}));

const loginSchema = yup.object().shape({
  email: yup
    .string()
    .email("Invalid email")
    .required("Email is required"),
  password: yup.string().required("Password is required")
});

export default function LoginForm({
  dialogMode = false,
  forceEmail = false,
  afterSubmit = false
}) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const loggedInEmail = useSelector(state => state?.session?.email);

  const { register, handleSubmit, errors } = useForm({
    validationSchema: loginSchema
  });

  const submitButton = (
    <SubmitButton loading={loading} className={classes.submit} />
  );

  const onSubmit = handleSubmit(payload => {
    setLoading(true);
    let email = payload.email;
    if (forceEmail && email !== loggedInEmail) {
      dispatch(logout("Invalid email address. Please login again."));
    } else {
      doApiRequest("pas/login/", {
        method: "POST",
        body: JSON.stringify(payload)
      }).then(({ error, data }) => {
        setLoading(false);
        if (error) {
          setError((data.errors || ["Server Error"]).join(<br />));
        } else if (!checkAuth()) {
          setError("Invalid cookie");
        } else {
          dispatch(login({ email, emailConfirmed: data.email_confirmed }));
          if (afterSubmit && afterSubmit instanceof Function) {
            afterSubmit();
          }
        }
      });
    }
  });

  return (
    <form style={{ width: "100%" /* fix IE 11 issue */ }} onSubmit={onSubmit}>
      <TextField
        variant="outlined"
        margin="normal"
        fullWidth
        label="Email"
        name="email"
        autoComplete="email"
        autoFocus
        inputRef={register}
        error={errors?.email ? true : false}
        helperText={errors?.email?.message}
        inputProps={{ autoCapitalize: "none" }}
      />
      <TextField
        variant="outlined"
        margin="normal"
        fullWidth
        name="password"
        label="Password"
        type="password"
        autoComplete="current-password"
        inputRef={register}
        error={errors?.password ? true : false}
        helperText={errors?.password?.message}
      />
      {error ? (
        <Alert severity="error" className={classes.error}>
          {error}
        </Alert>
      ) : null}
      {dialogMode ? (
        <DialogActions>
          {submitButton}
          <Button onClick={() => dispatch(logout(""))} color="primary">
            Exit
          </Button>
        </DialogActions>
      ) : (
        submitButton
      )}
      {!loading && <Captcha inputRef={register} />}
    </form>
  );
}
