import React, { useState, forwardRef } from "react";
import { useDispatch } from "react-redux";
import useQuestionState from "./useQuestionStateHook";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Grid, Typography, Slider } from "@material-ui/core";

const VASSlider = withStyles({
  disabled: {
    color: "transparent"
  }
})(Slider);

const useStyles = makeStyles({
  root: {
    padding: "15px 20px 10px 20px"
  }
});

function createMarks({ min, max, displayMinMaxValues, displayCenterMark }) {
  return [
    ...(displayMinMaxValues ? [{ value: min, label: min }] : []),
    ...(displayCenterMark ? [{ value: (min + max) / 2, label: "" }] : []),
    ...(displayMinMaxValues ? [{ value: max, label: max }] : [])
  ];
}

function parseResponse(value) {
  return value ? parseInt(value) : null;
}

function VisualAnalogScale({ vas, ...props }, ref) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { response, userSkipped, saveResponse } = useQuestionState(props);
  const [value, setValue] = useState(parseResponse(response?.value));

  const handleChange = (event, newValue) => {
    if (newValue !== value) {
      dispatch(saveResponse({ value: newValue }));
    }
    setValue(newValue);
  };

  const options = {
    min: vas.vas_minimum,
    max: vas.vas_maximum,
    step: vas.vas_interval,
    defaultValue: (vas.vas_minimum + vas.vas_maximum) / 2,
    valueLabelDisplay: vas.vas_display_cursor_value,
    displayMinMaxValues: vas.vas_display_extrema,
    displayCenterMark: vas.vas_display_center_mark,
    labels: {
      min: vas.vas_min_label,
      median: vas.vas_mid_label,
      max: vas.vas_max_label
    }
  };

  return (
    <div className={classes.root}>
      <VASSlider
        ref={ref}
        value={value ?? options.defaultValue}
        defaultValue={options.defaultValue}
        aria-labelledby="slider"
        valueLabelDisplay={options.valueLabelDisplay ? "auto" : "off"}
        min={options.min}
        max={options.max}
        step={options.step}
        track={false}
        marks={createMarks(options)}
        disabled={userSkipped}
        onChange={handleChange}
      />
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="flex-start"
      >
        <Typography gutterBottom>{options?.labels?.min}</Typography>
        <Typography gutterBottom>{options?.labels?.median}</Typography>
        <Typography gutterBottom>{options?.labels?.max}</Typography>
      </Grid>
    </div>
  );
}

export default forwardRef(VisualAnalogScale);
