import React, { forwardRef } from "react";
import useTextResponse from "./useTextResponseHook";
import { TextField } from "@material-ui/core";
import InputMask from "react-input-mask";
import * as yup from "yup";

const schema = yup
  .string()
  .matches(
    /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/,
    { message: "Phone number is not valid", excludeEmptyString: true }
  );

function TextResponsePhone(props, ref) {
  const {
    value,
    flagMissing,
    error,
    userSkipped,
    handleOnChange,
    handleOnBlur,
    variant,
    margin
  } = useTextResponse(props, { schema });

  return (
    <InputMask
      value={userSkipped ? "" : value}
      onChange={handleOnChange}
      onBlur={handleOnBlur}
      mask="(999) 999-9999"
    >
      {() => (
        <TextField
          inputRef={ref}
          error={flagMissing || Boolean(error)}
          helperText={error?.message}
          variant={variant}
          margin={margin}
        />
      )}
    </InputMask>
  );
}

export default forwardRef(TextResponsePhone);
