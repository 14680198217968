import React, { useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { fetchInstrument } from "../reducers/instrumentSlice";
import { LinearProgress } from "@material-ui/core";

import Instrument from "./Instrument";

export default function Debugger() {
  let { id: instrumentId } = useParams();
  const history = useHistory();
  const instrument = useSelector(
    state => state.instrument?.[instrumentId]?.object
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchInstrument({ instrumentId }));
  }, [dispatch, instrumentId]);

  function handleItemComplete() {
    history.push("/queues");
  }

  if (!instrument) {
    return <LinearProgress />;
  }

  return (
    <Instrument
      key={instrumentId}
      instrumentId={instrumentId}
      assessmentId={-1}
      handleItemComplete={handleItemComplete}
      completeOnFinish={false}
    />
  );
}
