import React, { useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles
} from "@material-ui/core";

import doApiRequest from "../../ApiClient";
import { setSectionTimeOut } from "../../reducers/instrumentSlice";

const useStyles = makeStyles(theme => ({
  centerAlign: {
    textAlign: "center"
  },
  submit: {
    margin: theme.spacing(2, 0, 2)
  },
  dialogbackdrop: {
    filter: "blur(6px)"
  },
  alignTimer: {
    textAlign: "right",
    padding: theme.spacing(0, 3)
  }
}));

export function SectionTimer({
  instrumentId,
  assessmentId,
  section,
  frameRef
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { responses } = useSelector(state => state.assessment?.[assessmentId]);
  const saPreview = assessmentId === -1;
  const [clock, setClock] = useState();
  const hasHours = useMemo(() => {
    return Math.floor(section.time_limit / 3600) > 0;
  }, [section.time_limit]);

  useEffect(() => {
    const now = Date.now();
    setClock(section.timerEndsAt - now);
    if (!section.timerEndsAt) {
      let isTimedOut = section.pages
        .flat()
        .every(questionId => Object.keys(responses).includes(questionId));
      dispatch(
        setSectionTimeOut({
          instrumentId,
          sectionId: section.section_id,
          timerEndsAt: isTimedOut
            ? now
            : section.timerEndsAt ?? now + section.time_limit * 1000
        })
      );
    }
  }, [
    section.section_id,
    dispatch,
    instrumentId,
    responses,
    section.pages,
    section.time_limit,
    section.timerEndsAt
  ]);

  useEffect(() => {
    let sectionTimer;
    const addTimedOutResponses = () => {
      doApiRequest(
        `asmt/assessment/time-out/${assessmentId}/${section.section_id}/`
      );
    };

    sectionTimer = setInterval(() => {
      timerFunctionality();
    }, 250);
    const timerFunctionality = () => {
      const now = Date.now();
      if (now <= section.timerEndsAt) {
        frameRef.current.children[0].classList.remove(classes.dialogbackdrop);
        setClock(section.timerEndsAt - now);
      } else {
        frameRef.current.children[0].classList.add(classes.dialogbackdrop);
        clearInterval(sectionTimer);
        if (!saPreview) addTimedOutResponses();
      }
    };

    return () => {
      clearInterval(sectionTimer);
    };
  }, [
    assessmentId,
    section.section_id,
    section.timerEndsAt,
    saPreview,
    classes.dialogbackdrop,
    frameRef,
    setClock
  ]);

  function getCountDownText(tDifference, timeLimit) {
    tDifference = tDifference > 0 ? Math.floor(tDifference / 1000) : 0;
    let tSeconds = tDifference % 60;
    let tMinutes = Math.floor(tDifference / 60) % 60;
    let tHours = Math.floor(tDifference / 3600);
    return (
      (hasHours ? tHours + ":" : "") +
      (hasHours ? tMinutes.toString().padStart(2, "0") : tMinutes) +
      ":" +
      tSeconds.toString().padStart(2, "0")
    );
  }

  return (
    section.time_limit != null && (
      <>
        <div className={classes.alignTimer}>
          <h2>{getCountDownText(clock, section.time_limit)}</h2>
        </div>

        {Date.now() > section.timerEndsAt && (
          <Dialog
            open
            disableBackdropClick
            container={() => frameRef.current}
            BackdropProps={{ style: { position: "absolute" } }}
            style={{ position: "absolute" }}
          >
            <DialogTitle className={classes.centerAlign}>
              Time is up.
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                Your answers have been saved.
              </DialogContentText>
            </DialogContent>
          </Dialog>
        )}
      </>
    )
  );
}
