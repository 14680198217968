import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { startQueue, completeQueue } from "../reducers/queueSlice";
import { useHistory, useLocation } from "react-router-dom";
import { Button, LinearProgress, Typography } from "@material-ui/core";

import ErrorMessage from "../Components/ErrorMessage";
import Instrument from "../Instrument";
import ProgressPrompt from "./QueueProgressPrompt";
import Message from "../Components/Message";

export default function Queue({ queueId, directQueue = false }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const debugMode = new URLSearchParams(useLocation().search).has("debug");

  const userQueuesFetched = useSelector(({ queue }) =>
    Boolean(!queue?.isLoading && queue?.lastUpdated)
  );
  const {
    assessments = [],
    queue_type_label: queueType,
    nonstop_queue: nonstopQueue,
    start_date,
    end_date
  } = useSelector(state => state.queue.allQueues?.[queueId] || {});

  const [error, setError] = useState(false);
  const [initCalled, setInitCalled] = useState(false);
  const [dateStatus, setDateStatus] = useState(false);
  const [showProgressPrompt, setShowProgressPrompt] = useState(false);
  const [itemIndex, setItemIndex] = useState(-1);

  const nextQueueItem = useCallback(() => {
    const nextItemIndex = assessments.findIndex(
      ({ isComplete }, index) => !isComplete && index > itemIndex
    );

    setItemIndex(nextItemIndex);
  }, [assessments, itemIndex]);

  const backButton = (
    <Button
      color="primary"
      onClick={() => {
        history.length ? history.goBack() : history.push("/queues");
      }}
    >
      Go Back
    </Button>
  );

  useEffect(() => {
    if (assessments.filter(assessment => assessment.assessment_id).length > 0) {
      // queue already started
      setDateStatus("good");
    } else {
      if (userQueuesFetched && !dateStatus) {
        const now = Date.now();
        if (end_date && now > new Date(end_date)) {
          setDateStatus("late");
        } else if (start_date && now < new Date(start_date)) {
          setDateStatus("early");
        } else {
          setDateStatus("good");
        }
      }
    }
  }, [
    assessments,
    userQueuesFetched,
    dateStatus,
    start_date,
    end_date,
    setDateStatus
  ]);

  useEffect(() => {
    if (!initCalled && queueId && userQueuesFetched && dateStatus === "good") {
      setInitCalled(true);
      dispatch(startQueue(queueId)).then(({ payload, error }) => {
        if (error) {
          setError(payload);
          return;
        }
        nextQueueItem(); // start first queue item
      });
    }
  }, [
    dispatch,
    initCalled,
    queueId,
    userQueuesFetched,
    nextQueueItem,
    dateStatus
  ]);

  async function handleItemComplete(args) {
    const { noPrompt = false } = args || {};
    const shouldPrompt = !(noPrompt || nonstopQueue);
    const noQueueItemsRemaining = itemIndex >= assessments.length - 1;

    if (noQueueItemsRemaining) {
      await dispatch(completeQueue(queueId));
      if (directQueue) {
        setShowProgressPrompt(true);
      } else if (shouldPrompt) {
        // show queue progress prompt (Queue Complete)
        setShowProgressPrompt(true);
      } else {
        // return to queues page after last queue item
        history.push("/queues");
      }
    } else {
      if (shouldPrompt) {
        // show queue progress prompt
        setShowProgressPrompt(true);
      } else {
        // continue on to the next queue item
        nextQueueItem();
      }
    }
  }

  // continue to next queue item after user prompt
  function handleUserPromptContinue() {
    setShowProgressPrompt(false);
    nextQueueItem();
  }

  if (error) {
    return <ErrorMessage error={error} />;
  }

  if (dateStatus === "early") {
    return (
      <Message>
        <Typography gutterBottom>
          This queue is not available yet. Please check back later.
        </Typography>
        {!directQueue && backButton}
      </Message>
    );
  }

  if (dateStatus === "late") {
    return (
      <Message>
        <Typography gutterBottom>This queue is expired.</Typography>
        {!directQueue && backButton}
      </Message>
    );
  }

  if (!userQueuesFetched || itemIndex === -1) {
    return <LinearProgress />;
  }
  if (showProgressPrompt) {
    return (
      <ProgressPrompt
        key={`${queueId}-${itemIndex}`}
        queueProgress={{ itemIndex, assessments }}
        handleUserPromptContinue={handleUserPromptContinue}
        directQueue={directQueue}
      />
    );
  }

  // current queue item
  const {
    instrument_id: instrumentId,
    queue_instrument_id: queueInstrumentId,
    assessment_id: assessmentId
  } = assessments?.[itemIndex] || {};

  const completeOnFinish = queueType !== "On-going";

  return (
    <Instrument
      key={`${instrumentId}-${queueInstrumentId}`}
      queueId={queueId}
      instrumentId={instrumentId}
      queueInstrumentId={queueInstrumentId}
      assessmentId={assessmentId}
      handleItemComplete={handleItemComplete}
      completeOnFinish={completeOnFinish}
      debugMode={debugMode}
    />
  );
}
