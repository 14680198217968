import React, { useContext } from "react";
import { AssessmentContext } from "../../../AppContext/AssessmentContext";
import useQuestionState from "../useQuestionStateHook";
import { makeStyles } from "@material-ui/core/styles";
import { Paper } from "@material-ui/core";

import { UserSkippable, ConditionalySkipped } from "./SkippableQuestion";

const useStyles = makeStyles(theme => ({
  container: {
    padding: "0px 0px 0px 0px",
    [theme.breakpoints.up("sm")]: {
      padding: "0px 25px 0px 25px"
    }
  },
  content: {
    paddingTop: "10px"
  },
  flagOff: {
    border: "1px solid transparent"
  },
  flagOn: {
    border: "1px solid red"
  }
}));

export default function QuestionContainer({
  compact,
  userSkippable,
  questionIds,
  flagIfMissing,
  children
}) {
  const classes = useStyles();

  const { hideCondSkipped, skippedQIDs, showErrors } = useContext(
    AssessmentContext
  );
  const { isValidResponse } = useQuestionState({
    question_id: questionIds?.[0]
  });

  const flag =
    flagIfMissing && showErrors && !isValidResponse
      ? classes.flagOn
      : classes.flagOff;

  const isCondSkipped = questionIds.every(questionId =>
    skippedQIDs.includes(questionId)
  );

  if (hideCondSkipped && isCondSkipped) {
    return null;
  }

  return (
    <div className={classes.container}>
      <Paper square={compact} className={`${classes.content} ${flag}`}>
        {isCondSkipped && <ConditionalySkipped>{children}</ConditionalySkipped>}
        {!isCondSkipped && userSkippable && (
          <UserSkippable questionId={questionIds?.[0]}>
            {children}
          </UserSkippable>
        )}
        {!isCondSkipped && !userSkippable && children}
      </Paper>
    </div>
  );
}
