import { store } from "./AppContext/StateContext";
import { setSessionExpire } from "./reducers/sessionSlice";

export default async function doApiRequest(url, options = {}) {
  const {
    session: { dqCode }
  } = store.getState();

  if (dqCode) {
    options.headers = Object.assign({}, options?.headers, {
      "dq-code": dqCode
    });
  }

  const { error, data, headers } = await doRequest(`/p3api/${url}`, options);

  const sessionExpire = headers?.get("http-session-expire");
  if (sessionExpire) {
    store.dispatch(setSessionExpire(sessionExpire));
  }

  if (error) {
    return { error, data };
  }
  return { error, data: data?.data || data };
}

export async function doRequest(url, options) {
  let response;

  try {
    response = await fetch(url, options);
    const error = !response.ok;
    const data = await response.json();
    return { error, data, headers: response.headers };
  } catch (error) {
    return { error: true, data: { error, response } };
  }
}
