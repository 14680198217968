import React from "react";
import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  makeStyles,
  Typography
} from "@material-ui/core";
import { useHistory } from "react-router";

import imageWelcome from "../assets/homepage/welcome.svg";
import imageStudies from "../assets/homepage/studies.svg";
import imageQueues from "../assets/homepage/queues.svg";
//import imageCalendar from "../assets/homepage/slide-assessment-scheduling.jpg";
import imageRadiology from "../assets/homepage/radiology.svg";
import imagePayments from "../assets/homepage/payments.svg";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  maxHeight: {
    height: "100%"
  },
  image: {
    objectFit: "contain"
  },
  link: {
    textDecoration: "none"
  }
}));

export default function HomePage() {
  const classes = useStyles();
  const itemProps = { sm: 6, xs: 12 };

  return (
    <div className={classes.root}>
      <Grid container spacing={4} justify="center" alignItems="stretch">
        <Grid item xs={12}>
          <HomeCard
            title="Welcome!"
            text="On this page you can learn more about the participant portal and how to access your studies and assessments."
            image={imageWelcome}
          />
        </Grid>
        <Grid item {...itemProps}>
          <HomeCard
            title="Studies"
            text={
              "On the studies page you will find studies in which you are currently enrolled as well as studies that are currently recruiting new participants." +
              " If you eligible for a study click Enroll or contact the study staff listed with the study information."
            }
            link="https://coins.zendesk.com/hc/en-us/articles/360053474374-Studies"
            image={imageStudies}
            action="/studies"
          />
        </Grid>
        <Grid item {...itemProps}>
          <HomeCard
            title="Queues"
            text={
              "On the queue page you will find the assessment queues that have been assigned to you organized by study." +
              " Clicking start next to the queue will begin the first assessment in that list. There are several different types of queues. " +
              " Click more info to read about them. "
            }
            link="https://coins.zendesk.com/hc/en-us/articles/360053871414-Queues"
            image={imageQueues}
            action="/queues"
          />
        </Grid>
        <Grid item {...itemProps}>
          <HomeCard
            title="Radiology Letters"
            text="On this page you will be able to download your radiology review letter for your MRI scans that have been read by a radiologist."
            link="https://coins.zendesk.com/hc/en-us/articles/360053871634-Radiology-letters"
            image={imageRadiology}
            action="radiology"
          />
        </Grid>
        {/* <Grid item {...itemProps}>
          <HomeCard
            title="Calendar"
            text={
              "On the calendar page you will see all of the events for your studies." +
              "These events include assessment queues and individual event reminders made by your study staff."
            }
            link=""
            image={imageCalendar}
            action="calendar"
          />
        </Grid> */}
        <Grid item {...itemProps}>
          <HomeCard
            title="Payments"
            text={
              "On the payments page you will be able to see the amount you have been paid for each study and accross all studies." +
              "This information may be useful come tax season. Click more info to learn more."
            }
            link="https://coins.zendesk.com/hc/en-us/articles/360053871594-Payments"
            image={imagePayments}
            action="payments"
          />
        </Grid>
      </Grid>
    </div>
  );
}

function HomeCard({ title, image, text, link, action }) {
  const classes = useStyles();
  const history = useHistory();

  const content = (
    <>
      {image && (
        <CardMedia
          component="img"
          height="160"
          src={image}
          className={classes.image}
        />
      )}
      <CardContent>
        <Typography variant="h5" gutterBottom>
          {title}
        </Typography>
        <Typography variant="body2">{text}</Typography>
      </CardContent>
    </>
  );

  return (
    <Card className={classes.maxHeight}>
      <Grid /* to push buttons to the bottom */
        container
        direction="column"
        justify="space-between"
        className={classes.maxHeight}
      >
        <Grid item>
          {action ? (
            <CardActionArea onClick={() => history.push(action)}>
              {content}
            </CardActionArea>
          ) : (
            content
          )}
        </Grid>
        {link && (
          <Grid>
            <CardActions>
              <a
                href={link}
                target="_blank"
                rel="noopener noreferrer"
                className={classes.link}
              >
                <Button color="primary">More Info</Button>
              </a>
            </CardActions>
          </Grid>
        )}
      </Grid>
    </Card>
  );
}
