import React, { useState, useEffect } from "react";
import { Typography } from "@material-ui/core";
import { useParams } from "react-router";

import RegisterSubject from "./RegisterSubject";
import EnrollSubject from "./EnrollSubject";
import doApiRequest from "../ApiClient";
import useStypeData from "./useStypeData";
import Message from "../Components/Message";

export default function Registration() {
  const { stype } = useParams();
  const stypeData = useStypeData(stype);

  const [action, setAction] = useState();

  const passParams = {
    stype,
    setAction,
    stypeData
  };

  if (!action && stype) {
    setAction("check-stype");
  }

  switch (action) {
    case "check-stype":
      return <CheckStype {...passParams} />;
    case "register":
      return <RegisterSubject {...passParams} />;
    case "enroll":
      return <EnrollSubject {...passParams} />;
    default:
      return <NoStype />;
  }
}

function CheckStype({ stype, setAction }) {
  const [message, setMessage] = useState(null);

  useEffect(() => {
    doApiRequest(`pas/check-account-and-stype/${stype}/`).then(({ data }) => {
      if (
        data.errors &&
        (data.errors[0] === "stype not found" || data.errors[0] === "bad stype")
      ) {
        setMessage(<NoStype />);
        return;
      }

      switch (data) {
        case "enrolled":
          setMessage(
            <Message>You are already enrolled in this study.</Message>
          );
          break;

        case "subject at site":
          setAction("enroll");
          break;

        case "no subject":
          setAction("register");
          break;

        default:
          console.log(data);
          setMessage(
            <Error>Unexpected error. Please contact study staff.</Error>
          );
      }
    });
  }, [stype, setAction]);

  return message;
}

function Error({ children }) {
  return (
    <Message>
      <Typography style={{ color: "darkred" }}>{children}</Typography>
    </Message>
  );
}

function NoStype() {
  return (
    <Error>
      Invalid URL. Please verify your enrollment URL with study staff.
    </Error>
  );
}
