import { useState, useEffect, useContext } from "react";
import { useDispatch } from "react-redux";
import { AssessmentContext } from "../../../AppContext/AssessmentContext";
import useQuestionState from "../useQuestionStateHook";
import moment from "moment";

function parseResponse(value, format) {
  const date = moment(value, format, true);
  return date.isValid() ? date : null;
}

export default function useTextResponseDateTimeHook(props) {
  const { format, views } = props;
  const { showErrors } = useContext(AssessmentContext);
  const dispatch = useDispatch();

  const {
    isValidResponse,
    response,
    userSkipped,
    saveResponse
  } = useQuestionState(props);

  const [value, setValue] = useState(parseResponse(response?.value, format));
  const [error, setError] = useState(null);

  useEffect(() => {
    if (value) {
      const date = moment(value, format, true);
      const isValid = date.isValid();
      setError(!isValid);
      dispatch(saveResponse({ value: isValid ? date.format(format) : null }));
    }
  }, [dispatch, format, saveResponse, value]);

  const flagMissing =
    props.parentType === "TABLE" && showErrors && !isValidResponse;

  return {
    value,
    flagMissing,
    error,
    userSkipped,
    handleOnChange: setValue,
    format,
    views
  };
}
