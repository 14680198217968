import React, { useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Container, Typography } from "@material-ui/core";

import { AssessmentContext } from "../../../AppContext/AssessmentContext";
import useQuestionState from "../useQuestionStateHook";

const useStyles = makeStyles({
  displayed: {
    fontSize: "0.8125rem",
    visibility: "visible"
  },
  hidden: {
    fontSize: "0.8125rem",
    visibility: "hidden",
    height: 0,
    margin: "0px 0px 0px 0px"
  },
  button: {
    padding: 0,
    zIndex: "101"
  },
  noPad: {
    padding: 0
  }
});

export default function SkipButton({ questionId }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { instrumentId } = useContext(AssessmentContext);
  const skipLabel = useSelector(
    state =>
      state.instrument?.[instrumentId]?.object?.skip_question_text ||
      "Skip Question"
  );
  const { userSkipped, toggleUserSkipped } = useQuestionState({
    question_id: questionId
  });

  function handleClick(event) {
    event.preventDefault();
    dispatch(toggleUserSkipped());
  }

  return (
    <Button
      size="small"
      color={userSkipped ? "secondary" : "primary"}
      onClick={handleClick}
      className={classes.button}
    >
      <Container className={classes.noPad}>
        <Typography
          className={userSkipped ? classes.hidden : classes.displayed}
        >
          {skipLabel}
        </Typography>
        <Typography
          className={userSkipped ? classes.displayed : classes.hidden}
        >
          Unskip
        </Typography>
      </Container>
    </Button>
  );
}
