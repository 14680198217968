import React, { useState, useEffect, useMemo } from "react";
import {
  Button,
  Card,
  Container,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Grid,
  List,
  ListItem,
  Typography,
  makeStyles,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  ListSubheader
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useHistory } from "react-router";

import doApiRequest from "../ApiClient";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3)
  },
  padBottom: {
    paddingBottom: theme.spacing(2)
  },
  padTop: {
    paddingTop: theme.spacing(2)
  },
  card: {
    padding: theme.spacing(2)
  },
  selectSite: {
    width: 300
  },
  extraIndent: {
    paddingLeft: "20%"
  },
  siteText: {
    whiteSpace: "pre-wrap"
  }
}));

const studyInfoStyles = makeStyles(theme => ({
  heading: {
    fontWeight: theme.typography.fontWeightBold
  }
}));

export default function Studies() {
  const classes = useStyles();
  const allSites = "all-sites";

  const [previousStudies, setPreviousStudies] = useState([]);
  const [currentStudies, setCurrentStudies] = useState([]);
  const [enrolledStudiesLoaded, setEnrolledStudiesLoaded] = useState(false);

  const [recruitmentStudies, setRecruitmentStudies] = useState([]);
  const [selectedSite, setSelectedSite] = useState("");
  const [recruitmentStudiesLoaded, setRecruitmentStudiesLoaded] = useState(
    false
  );
  const [states, setStates] = useState([]);

  useEffect(() => {
    doApiRequest("studies/enrolled").then(({ data }) => {
      setPreviousStudies(data.previous_studies);
      setCurrentStudies(data.current_studies);
      setEnrolledStudiesLoaded(true);
    });
  }, []);

  useEffect(() => {
    doApiRequest("studies/recruitment").then(({ data }) => {
      setRecruitmentStudies(data);
      setRecruitmentStudiesLoaded(true);
    });
  }, []);

  useEffect(() => {
    doApiRequest(`pas/states/US`).then(({ data }) => {
      setStates(data);
    });
  }, []);

  const reducedSites = useMemo(
    () =>
      recruitmentStudies.reduce((states, study) => {
        const stateName = study.state ?? "Other";
        const cityName = study.city ?? "Other";
        return {
          ...states,
          [stateName]: {
            ...(states?.[stateName] ?? {}),
            [cityName]: {
              ...(states?.[stateName]?.[cityName] ?? {}),
              [study.site_id.toString()]: study.site_label
            }
          }
        };
      }, {}),
    [recruitmentStudies]
  );

  const handleChange = event => {
    if (event.target.value) {
      setSelectedSite(event.target.value);
    }
  };

  const selectedStudies = useMemo(() => {
    if (!selectedSite) {
      //select default site
      let currentStudyIds = currentStudies.map(x => x.site_id);
      let previousStudyIds = previousStudies.map(x => x.site_id);
      let siteToSelect =
        recruitmentStudies.filter(x => currentStudyIds.includes(x.site_id))[0]
          ?.site_id ??
        recruitmentStudies.filter(x => previousStudyIds.includes(x.site_id))[0]
          ?.site_id;

      if (siteToSelect) {
        setSelectedSite(siteToSelect);
        return recruitmentStudies.filter(
          study => study.site_id === siteToSelect
        );
      } else return recruitmentStudies;
    } else if (selectedSite === allSites) return [...recruitmentStudies];
    else
      return recruitmentStudies.filter(study => study.site_id === selectedSite);
  }, [recruitmentStudies, selectedSite, currentStudies, previousStudies]);

  return (
    <Container className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Card className={classes.card}>
            <Typography variant="h5" className={classes.padBottom}>
              Enrolled Studies
            </Typography>
            <Typography variant="body1" className={classes.padBottom}>
              Below is a list of studies in which you are enrolled.
            </Typography>
            <Typography variant="h6" className={classes.padBottom}>
              Current Studies
            </Typography>
            {enrolledStudiesLoaded ? (
              currentStudies.map(study => (
                <StudyInfo key={study.study_id} {...study} />
              ))
            ) : (
              <StudyInfoSkeleton />
            )}
            <Typography
              variant="h6"
              className={`${classes.padBottom} ${classes.padTop}`}
            >
              Previous Studies
            </Typography>
            {enrolledStudiesLoaded ? (
              previousStudies.map(study => (
                <StudyInfo key={study.study_id} {...study} />
              ))
            ) : (
              <StudyInfoSkeleton />
            )}
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card className={classes.card}>
            <Typography variant="h5" className={classes.padBottom}>
              Recruiting Studies
            </Typography>

            {recruitmentStudiesLoaded ? (
              <>
                <div className={classes.padBottom}>
                  <FormControl
                    variant="outlined"
                    className={classes.selectSite}
                  >
                    <InputLabel id="site_select">Filter by location</InputLabel>
                    <Select
                      labelId="site_select"
                      value={selectedSite}
                      onChange={handleChange}
                      label="Filter by location"
                    >
                      <MenuItem key={allSites} value={allSites}>
                        <b className={classes.siteText}>All Locations</b>
                      </MenuItem>
                      {Object.keys(reducedSites).map((state, index) => [
                        <ListSubheader>
                          <em>
                            {states.filter(x => x.code === state)[0]?.name ??
                              "Other"}
                          </em>
                        </ListSubheader>,
                        Object.keys(reducedSites[state]).map(city => [
                          <ListSubheader className={classes.extraIndent}>
                            <em>{city}</em>
                          </ListSubheader>,
                          Object.keys(reducedSites[state][city]).map(site => {
                            return (
                              <MenuItem
                                className={classes.extraIndent}
                                key={site}
                                value={site}
                              >
                                <b className={classes.siteText}>
                                  {reducedSites[state][city][site]}
                                </b>
                              </MenuItem>
                            );
                          })
                        ])
                      ])}
                    </Select>
                  </FormControl>
                </div>

                {selectedStudies.map(study => (
                  <StudyInfo key={study.study_id} {...study} />
                ))}
              </>
            ) : (
              <StudyInfoSkeleton />
            )}
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}

function StudyInfoSkeleton() {
  return (
    <>
      <Skeleton type="rect" height={48} />
      <Skeleton type="rect" height={48} />
      <Skeleton type="rect" height={48} />
    </>
  );
}

function StudyInfo(props) {
  const classes = studyInfoStyles();
  const history = useHistory();

  return (
    <ExpansionPanel>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <Typography className={classes.heading}>{props.irb_number}</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <List className={classes.infoList} dense>
          <InfoDetail label="Study Title" value={props.hrrc_title} />
          <InfoDetail label="Study Purpose" value={props.study_purpose} />
          <InfoDetail label="Protocol Summary" value={props.protocol_summary} />
          {props.contact_info ? (
            <InfoDetail
              label="Contact Info"
              value={
                <List dense>
                  <ListItem>{props.contact_info.contact_name}</ListItem>
                  <ListItem>{props.contact_info.contact_email}</ListItem>
                  <ListItem>{props.contact_info.contact_phone}</ListItem>
                </List>
              }
            />
          ) : null}
          {props.criteria ? (
            <InfoDetail
              label="Basic Criteria"
              value={
                <List dense>
                  {props.criteria.map(criterion => (
                    <InfoDetail
                      key={criterion.label}
                      label={criterion.label}
                      value={criterion.value}
                    />
                  ))}
                </List>
              }
            />
          ) : null}
          {props.subject_type_id ? (
            <ListItem>
              <Button
                color="primary"
                variant="contained"
                className={classes.enrollButton}
                style={{ margin: "auto" }}
                onClick={() => {
                  history.push(`/registration/${props.subject_type_id}`);
                }}
              >
                Enroll
              </Button>
            </ListItem>
          ) : null}
        </List>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
}

function InfoDetail({ label, value }) {
  if (!value) {
    return null;
  }

  return (
    <ListItem>
      <Typography variant="body2" component="span">
        <b>{label}:</b>&nbsp;{value}
      </Typography>
    </ListItem>
  );
}
