import React, { useContext, forwardRef } from "react";
import { AssessmentContext } from "../../../AppContext/AssessmentContext";
import { makeStyles } from "@material-ui/core/styles";
import useCannedResponseState from "./useCannedResponseStateHook";
import {
  List,
  ListItem,
  ListItemText,
  Radio,
  FormGroup,
  TableCell,
  TableRow
} from "@material-ui/core";

import DangerousTypography from "../../Components/DangerousTypography";
import SpecifyDetail from "./SpecifyDetail";
import SkipButton from "../Components/SkipButton";

const useStyles = makeStyles({
  root: {
    padding: "0px 5px"
  },
  halfOpaque: {
    opacity: "0.5",
    "& *": {
      cursor: "default"
    }
  },
  greyed: {
    color: "lightgrey"
  },
  noPad: {
    padding: 0
  }
});

function CannedResponseRadio(props, ref) {
  const classes = useStyles();
  const { showErrors } = useContext(AssessmentContext);
  const {
    isValidResponse,
    state,
    userSkipped,
    setSelected,
    setDetail
  } = useCannedResponseState(props);

  const { parentType } = props;

  if (parentType === "LIKERT_GRID") {
    const {
      sa_label,
      label,
      question_id: questionId,
      is_required: isRequired
    } = props;
    return (
      <TableRow ref={ref} selected={showErrors && !isValidResponse}>
        <TableCell
          component="th"
          scope="row"
          className={userSkipped ? classes.greyed : ""}
        >
          <DangerousTypography>{sa_label || label}</DangerousTypography>
        </TableCell>
        {Object.entries(state).map(([responseId, { checked }]) => (
          <TableCell
            key={responseId}
            align="center"
            className={userSkipped ? classes.halfOpaque : ""}
          >
            <Radio
              color="primary"
              checked={userSkipped ? false : checked}
              onClick={() => setSelected(responseId)}
              className={classes.noPad}
            />
          </TableCell>
        ))}
        <TableCell>
          {!isRequired && <SkipButton questionId={questionId} />}
        </TableCell>
      </TableRow>
    );
  }

  return (
    <div ref={ref} className={classes.root}>
      <List>
        {Object.entries(state)
          .sort(([, { sortOrder: a }], [, { sortOrder: b }]) => a - b)
          .map(([responseId, { checked, label, isSpecifyDetail, detail }]) => {
            const labelId = `checkbox-list-label-${responseId}`;
            return (
              <FormGroup key={responseId}>
                <ListItem
                  role={undefined}
                  dense
                  button
                  onClick={() => setSelected(responseId)}
                >
                  <Radio
                    edge="start"
                    checked={userSkipped ? false : checked}
                    tabIndex={-1}
                    disableRipple
                    color="primary"
                    inputProps={{ "aria-labelledby": labelId }}
                  />
                  <ListItemText
                    id={labelId}
                    disableTypography
                    primary={<DangerousTypography>{label}</DangerousTypography>}
                  />
                </ListItem>
                {isSpecifyDetail && !userSkipped && (
                  <SpecifyDetail
                    show={checked}
                    savedValue={detail}
                    saveResponseDetail={detail => setDetail(responseId, detail)}
                  />
                )}
              </FormGroup>
            );
          })}
      </List>
    </div>
  );
}

export default forwardRef(CannedResponseRadio);
