import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  makeStyles,
  FormLabel,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from "@material-ui/core";
import { Alert, Autocomplete } from "@material-ui/lab";
import { KeyboardDatePicker } from "@material-ui/pickers";
import InputMask from "react-input-mask";
import moment from "moment";
import * as yup from "yup";

import SubmitButton from "../Components/SubmitButton";
import doApiRequest from "../ApiClient";

const useStyles = makeStyles(theme => ({
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  hidden: {
    display: "none"
  },
  header: {
    textAlign: "left",
    fontWeight: "bold",
    marginTop: theme.spacing(2)
  },
  form: {
    maxWidth: "600px"
  },
  input: {
    width: "100%"
  }
}));

const registrationSchema = yup.object().shape({
  first_name: yup.string().required("First name is required"),
  middle_name: yup.string(),
  last_name: yup.string().required("Last name is required"),
  suffix: yup.string(),
  birth_date: yup
    .date()
    .required("Date of birth is required")
    .typeError("Invalid date"),
  gender: yup.string().required("Gender is required"),

  address_country: yup
    .object()
    .test("country-check", "Country is required", country => {
      return Boolean(country?.code3);
    }),
  address_line_1: yup.string().required("Address is required"),
  address_line_2: yup.string(),
  address_state: yup.object().when("$hasStates", {
    is: true,
    then: yup.object().test("state-check", "State is required", state => {
      return Boolean(state?.code);
    })
  }),
  address_city: yup.string().required("City is required"),
  address_zip: yup.string().test("zip-check", "Invalid zip code", zip => {
    // mask should ensure numeric
    return zip.replace("_", "").length >= 5;
  }),

  phone1: yup
    .string()
    .required("Phone is required")
    .matches(
      /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/,
      { message: "Phone number is not valid", excludeEmptyString: true }
    ),
  extension1: yup.string().max(5, "Extensions cannot be greater than 5 digits"),
  phone2: yup
    .string()
    .matches(
      /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/,
      { message: "Phone number is not valid", excludeEmptyString: true }
    ),
  extension2: yup.string().max(5, "Extensions cannot be greater than 5 digits"),
  phone3: yup
    .string()
    .matches(
      /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/,
      { message: "Phone number is not valid", excludeEmptyString: true }
    ),
  extension3: yup.string().max(5, "Extensions cannot be greater than 5 digits")
});

const extractDigits = str => {
  return str.match(/\d/g).join("");
};

export default function RegisterSubject({ stype, stypeData, setAction }) {
  const [apiErrors, setApiError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [countryCode, setCountryCode] = useState("US");
  const [hasStates, setHasStates] = useState(false);
  const classes = useStyles();

  const {
    register,
    handleSubmit,
    setValue,
    clearError,
    errors,
    control
  } = useForm({
    validationSchema: registrationSchema,
    validationContext: { hasStates },
    defaultValues: {
      birth_date: null,
      address_country: { name: "United States", code2: "US", code3: "USA" },
      address_state: { name: "", code: "" },
      address_zip: "",
      phone1: "",
      extension1: "",
      phone2: "",
      extension2: "",
      phone3: "",
      extension3: ""
    }
  });

  const defaultInputOptions = {
    variant: "outlined",
    margin: "normal",
    className: classes.input,
    inputRef: register
  };

  const onSubmit = values => {
    const {
      // Don't include these values
      phone1,
      phone2,
      phone3,
      extension1,
      extension2,
      extension3,

      ...postData
    } = {
      ...values,
      subject_type_id: stype,
      birth_date: moment(values.birth_date).format("YYYY-MM-DD"),
      address_country: values.address_country.code3,
      address_state: values.address_state.code,
      phones: [
        {
          area_code: extractDigits(values.phone1).substring(0, 3),
          number: extractDigits(values.phone1).substring(3),
          extension: values.extension1
        }
      ]
    };

    if (values.phone2) {
      postData.phones.push({
        area_code: extractDigits(phone2).substring(0, 3),
        number: extractDigits(phone2).substring(3),
        extension: values.extension2
      });
    }

    if (values.phone3) {
      postData.phones.push({
        area_code: extractDigits(phone3).substring(0, 3),
        number: extractDigits(phone3).substring(3),
        extension: values.extension3
      });
    }

    doApiRequest("pas/register/", {
      method: "POST",
      body: JSON.stringify(postData)
    }).then(result => {
      if (result.error) {
        setApiError(result?.data?.errors || ["Unknown error"]);
        setLoading(false);
      } else {
        setAction("enroll");
      }
    });
  };

  const resetState = () => {
    clearError("address_state");
    setValue("address_state", { name: "", code: "" });
  };

  return (
    <div className={classes.paper}>
      <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
        <Grid container spacing={2} justify="center">
          <Grid item xs={12}>
            <Typography className={classes.header}>
              {stypeData?.label}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.header}>Personal Info</Typography>
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              label="First Name *"
              name="first_name"
              error={errors?.first_name ? true : false}
              helperText={errors?.first_name?.message}
              autoFocus
              {...defaultInputOptions}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              label="Middle Name"
              name="middle_name"
              error={errors?.middle_name ? true : false}
              helperText={errors?.middle_name?.message}
              {...defaultInputOptions}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              label="Last Name *"
              name="last_name"
              error={errors?.last_name ? true : false}
              helperText={errors?.last_name?.message}
              {...defaultInputOptions}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              label="Suffix"
              name="suffix"
              error={errors?.suffix ? true : false}
              helperText={errors?.suffix?.message}
              {...defaultInputOptions}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              as={KeyboardDatePicker}
              control={control}
              label="Date of Birth *"
              name="birth_date"
              format="MM/DD/YYYY"
              error={errors?.birth_date ? true : false}
              helperText={errors?.birth_date?.message}
              {...defaultInputOptions}
            />
          </Grid>
          <Grid item xs={12}>
            <FormLabel component="legend">Gender *</FormLabel>
            <RadioGroup name="gender">
              <FormControlLabel
                control={
                  <Radio
                    name="gender"
                    value="F"
                    color="primary"
                    inputRef={register}
                  />
                }
                label="Female"
              />
              <FormControlLabel
                control={
                  <Radio
                    name="gender"
                    value="M"
                    color="primary"
                    inputRef={register}
                  />
                }
                label="Male"
              />
              <FormControlLabel
                control={
                  <Radio
                    name="gender"
                    value="U"
                    color="primary"
                    inputRef={register}
                  />
                }
                label="Prefer not to answer"
              />
            </RadioGroup>
          </Grid>
        </Grid>
        <Grid container spacing={1} justify="center">
          <Grid item xs={12}>
            <Typography className={classes.header}>Address</Typography>
          </Grid>
          <Grid item xs={12}>
            <Controller
              as={CountryInput}
              setCountryCode={setCountryCode}
              control={control}
              name="address_country"
              resetState={resetState}
              error={errors?.address_country}
              {...defaultInputOptions}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Address Line 1 *"
              name="address_line_1"
              error={errors?.address_line_1 ? true : false}
              helperText={errors?.address_line_1?.message}
              {...defaultInputOptions}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Address Line 2"
              name="address_line_2"
              error={errors?.address_line_2 ? true : false}
              helperText={errors?.address_line_2?.message}
              {...defaultInputOptions}
            />
          </Grid>
          <Grid item xs={12} sm={4} className={hasStates ? "" : classes.hidden}>
            <Controller
              as={StateInput}
              countryCode={countryCode}
              control={control}
              name="address_state"
              error={errors?.address_state}
              setHasStates={setHasStates}
              {...defaultInputOptions}
            />
          </Grid>
          <Grid item xs={12} sm={hasStates ? 4 : 8}>
            <TextField
              label="City *"
              name="address_city"
              error={errors?.address_city ? true : false}
              helperText={errors?.address_city?.message}
              {...defaultInputOptions}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <MaskInput
              label="Zip Code *"
              name="address_zip"
              mask="99999"
              control={control}
              error={errors?.address_zip}
              {...defaultInputOptions}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.header}>Contact Info</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <PhoneInput
              name="phone1"
              label="Phone 1 *"
              control={control}
              error={errors?.phone1}
              {...defaultInputOptions}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ExtensionInput
              label="Extension 1"
              name="extension1"
              control={control}
              error={errors?.extension1}
              {...defaultInputOptions}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <PhoneInput
              name="phone2"
              label="Phone 2"
              control={control}
              error={errors?.phone2}
              {...defaultInputOptions}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ExtensionInput
              label="Extension 2"
              name="extension2"
              control={control}
              error={errors?.extension2}
              {...defaultInputOptions}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <PhoneInput
              name="phone3"
              label="Phone 3"
              control={control}
              error={errors?.phone3}
              {...defaultInputOptions}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ExtensionInput
              label="Extension 3"
              name="extension3"
              control={control}
              error={errors?.extension3}
              {...defaultInputOptions}
            />
          </Grid>
          <Grid item xs={12}>
            <SubmitButton
              loading={loading}
              className="button"
              buttonText="Continue"
            />
          </Grid>
        </Grid>
      </form>
      <br />
      {apiErrors && (
        <Alert severity="error">
          {apiErrors.map(error => (
            <Typography key={error}>{error}</Typography>
          ))}
        </Alert>
      )}
    </div>
  );
}

function CountryInput({
  error,
  control,
  inputRef,
  setCountryCode,
  onChange,
  onBlur,
  value,
  resetState,
  ...defaultInputOptions
}) {
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    doApiRequest("pas/countries/").then(({ data }) => {
      setCountries(data);
    });
  }, []);

  return (
    <Autocomplete
      disabled={countries.length === 0}
      options={countries}
      getOptionLabel={option => option.name}
      onChange={(_, data) => {
        if (data) {
          setCountryCode(data.code2);
          onChange(data);
        } else {
          setCountryCode("");
          onChange({ name: "", code2: "", code3: "" });
        }
        resetState();
      }}
      value={value}
      onBlur={onBlur}
      renderInput={params => (
        <TextField
          label="Country *"
          error={error ? true : false}
          helperText={error?.message}
          {...defaultInputOptions}
          {...params}
        />
      )}
    />
  );
}

function StateInput({
  error,
  control,
  inputRef,
  countryCode,
  onChange,
  onBlur,
  value,
  setHasStates,
  ...defaultInputOptions
}) {
  const [states, setStates] = useState([]);

  useEffect(() => {
    if (countryCode) {
      doApiRequest(`pas/states/${countryCode}`).then(({ data }) => {
        setStates(data);
      });
    }
  }, [countryCode]);

  useEffect(() => {
    setHasStates(states.length > 0);
  }, [states, setHasStates]);

  return (
    <Autocomplete
      disabled={states.length === 0}
      options={states}
      getOptionLabel={option => option.name}
      onChange={(_, data) => {
        if (data) {
          onChange(data);
        } else {
          onChange({ name: "", code: "" });
        }
      }}
      value={value}
      onBlur={onBlur}
      renderInput={params => (
        <TextField
          label="State *"
          error={error ? true : false}
          helperText={error?.message}
          {...params}
          {...defaultInputOptions}
        />
      )}
    />
  );
}

function PhoneInput(props) {
  return <MaskInput mask="(999) 999-9999" {...props} />;
}

function ExtensionInput(props) {
  return <MaskInput mask="99999" {...props} />;
}

function MaskInput({
  name,
  label,
  mask,
  control,
  error,
  inputRef,
  ...defaultInputOptions
}) {
  return (
    <Controller as={InputMask} control={control} name={name} mask={mask}>
      {() => (
        <TextField
          label={label}
          error={error ? true : false}
          helperText={error?.message}
          {...defaultInputOptions}
        />
      )}
    </Controller>
  );
}
