import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { LinearProgress } from "@material-ui/core";

import { fetchInstrument } from "../reducers/instrumentSlice";
import Instrument from "./Instrument";
import ProgressPrompt from "../Queues/QueueProgressPrompt";

export default function Preview() {
  const instrumentId = parseInt(useParams().id);
  const [showProgressPrompt, setShowProgressPrompt] = useState(false);
  const dispatch = useDispatch();
  const instrmentInState = useSelector(state =>
    Boolean(state.instrument?.[instrumentId]?.object)
  );
  const searchParams = new URLSearchParams(useLocation().search);
  const token = searchParams.get("t");
  const questionId = searchParams.get("q");
  const startAtQuestion = questionId && { questionId, instance: 1 }; // always first instance

  useEffect(() => {
    if (token) {
      dispatch(fetchInstrument({ instrumentId, token }));
      dispatch({
        type: "assessment/fetchAssessmentResponses/fulfilled",
        payload: {
          instrumentId,
          assessmentId: -1,
          responses: {},
          meta: {}
        }
      });
    }
  }, [dispatch, instrumentId, token]);

  function handleItemComplete() {
    setShowProgressPrompt(true);
  }

  if (!instrmentInState) {
    return <LinearProgress />;
  }

  if (showProgressPrompt) {
    return (
      <ProgressPrompt
        queueProgress={{ assessments: [] }}
        handleUserPromptContinue={window.close}
      />
    );
  }

  return (
    <Instrument
      key={instrumentId}
      instrumentId={instrumentId}
      assessmentId={-1}
      startAtQuestion={startAtQuestion}
      handleItemComplete={handleItemComplete}
      completeOnFinish={false}
    />
  );
}
