import React from "react";
import { useDispatch } from "react-redux";
import { logout } from "../reducers/sessionSlice";
import {
  AppBar,
  Toolbar,
  IconButton,
  Button,
  Link,
  Box
} from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import MenuIcon from "@material-ui/icons/Menu";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    flexGrow: 1
  },
  spacer: {
    flexGrow: 1
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      display: "none"
    }
  }
}));

export default function AppNavBar({ fullInterface, handleDrawerToggle }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  function handleLogout(event) {
    event.preventDefault();
    dispatch(logout());
  }

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar>
        {fullInterface && (
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
        )}
        <Link
          component={RouterLink}
          to="/"
          color="inherit"
          variant="h6"
          underline="none"
        >
          Participant Portal
        </Link>
        <Box className={classes.spacer} />
        <Button color="inherit" onClick={handleLogout}>
          Logout
        </Button>
      </Toolbar>
    </AppBar>
  );
}
