import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../reducers/sessionSlice";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@material-ui/core";

import logo from "../assets/logo.svg";
import LoginForm from "../Login/LoginForm";
import doApiRequest from "../ApiClient";

const useStyles = makeStyles(theme => ({
  logo: {
    height: "min(5vw, 20px)",
    pointerEvents: "none",
    margin: "0px"
  },
  centerAlign: {
    textAlign: "center"
  },
  submit: {
    margin: theme.spacing(2, 0, 2)
  },
  dialogbackdrop: {
    filter: "blur(6px)"
  }
}));

export default function SessionCheck() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { expire } = useSelector(state => state?.session);
  const [showWarning, setShowWarning] = useState(false);
  const [showLogin, setShowLogin] = useState(false); // also stores return to login page time

  const checkTick = 5000;

  useEffect(() => {
    if (showWarning || showLogin) {
      document.getElementById("root").classList.add(classes.dialogbackdrop);
    } else {
      document.getElementById("root").classList.remove(classes.dialogbackdrop);
    }
  }, [showWarning, showLogin, classes.dialogbackdrop]);

  useEffect(() => {
    let interval;
    if (!showLogin) {
      interval = setInterval(() => {
        const now = Date.now();
        if (now > expire - checkTick) {
          setShowLogin(now + 600000 /* 10 minutes */);
        } else if (now > expire - 60000 /* 1 minute */) {
          setShowWarning(true);
        } else {
          setShowWarning(false);
          setShowLogin(false);
        }
      }, checkTick);
    }
    return () => {
      clearInterval(interval);
    };
  }, [showLogin, expire, setShowLogin, setShowWarning]);

  // Move the user back to login page after the timeout popup has been up for 10 minutes
  useEffect(() => {
    let interval;
    if (showLogin) {
      interval = setInterval(() => {
        if (Date.now() > showLogin) {
          dispatch(logout("You have been logged out due to inactivity."));
        }
      }, checkTick);
    }
    return () => {
      clearInterval(interval);
    };
  }, [showLogin, dispatch]);

  const refreshSession = async () => {
    await doApiRequest("pas/refresh-session/");
  };

  return (
    <div>
      <Dialog open={showWarning} disableBackdropClick={true} maxWidth="xs">
        <DialogTitle className={classes.centerAlign}>
          <img src={logo} className={classes.logo} alt="COINS" />
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Would you like to stay logged in?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              refreshSession().then(() => setShowWarning(false));
            }}
            color="primary"
            autoFocus
          >
            Yes
          </Button>
          <Button onClick={() => dispatch(logout())} color="primary">
            No
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={Boolean(showLogin)}
        disableBackdropClick={true}
        maxWidth="xs"
      >
        <DialogTitle className={classes.centerAlign}>
          <img src={logo} className={classes.logo} alt="COINS" />
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Your screen has been locked due to inactivity. Please log in:
          </DialogContentText>
          <LoginForm
            dialogMode
            forceEmail
            afterSubmit={() => {
              setShowWarning(false);
              setShowLogin(false);
            }}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}
