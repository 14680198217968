import { createContext } from "react";

const state = {
  assessmentId: null,
  instrumentId: null,
  questionIdRefs: {}
};

export const AssessmentContext = createContext(state);

export default AssessmentContext.Provider;
