import React, { useState } from "react";
import { Container, Typography, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  displayed: {
    visibility: "visible"
  },
  hidden: {
    visibility: "hidden",
    height: 0
  }
}));

export default function StudyHover({ irb, label }) {
  const classes = useStyles();
  const [showHidden, setShowHidden] = useState(false);

  return (
    <Container
      onMouseOver={() => setShowHidden(true)}
      onMouseLeave={() => setShowHidden(false)}
    >
      <Typography className={showHidden ? classes.hidden : classes.displayed}>
        {irb}
      </Typography>
      <Typography className={showHidden ? classes.displayed : classes.hidden}>
        {label}
      </Typography>
    </Container>
  );
}
