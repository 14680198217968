import React from "react";
import useQuestionState from "../useQuestionStateHook";

import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import SkipButton from "./SkipButton";

const useStyles = makeStyles(theme => ({
  insideDiv: {
    width: "100%",
    height: "100%",
    position: "relative"
  },
  overlayDiv: {
    backgroundColor: "#FFF",
    position: "absolute",
    top: "-10px",
    opacity: "0.8",
    height: "100%",
    width: "100%",
    borderRadius: "6px",
    zIndex: "100"
  }
}));

export function UserSkippable({ questionId, children }) {
  const classes = useStyles();

  const { userSkipped } = useQuestionState({
    question_id: questionId
  });

  return (
    <div className={classes.insideDiv}>
      {children}
      <Grid container direction="row" justify="flex-end">
        <Grid item style={{ padding: "5px" }}>
          <SkipButton questionId={questionId} />
        </Grid>
      </Grid>
      {userSkipped && <div className={classes.overlayDiv} />}
    </div>
  );
}

export function ConditionalySkipped({ children }) {
  const classes = useStyles();
  return (
    <div className={classes.insideDiv}>
      {children}
      <div className={classes.overlayDiv} />
    </div>
  );
}
