import React, { useState, useEffect } from "react";
import { Input } from "@material-ui/core";
import LoadingOverlay from "react-loading-overlay";

export default function Captcha({ inputRef }) {
  const reCaptchaSiteKey = process.env.RECAPTCHA_SITE_KEY;
  const [reCaptchaToken, setReCaptchaToken] = useState("");

  useEffect(() => {
    const execute = () => {
      window.grecaptcha.ready(() => {
        window.grecaptcha.execute(reCaptchaSiteKey).then(token => {
          setReCaptchaToken(token);
        });
      });
    };

    let refreshCaptcha = setInterval(() => {
      execute();
    }, 2 * 60 * 1000);

    const script = document.createElement("script");
    window.onLoadCaptchaV3Callback = execute;
    script.src = `https://www.google.com/recaptcha/api.js?onload=onLoadCaptchaV3Callback&render=${reCaptchaSiteKey}`;
    document.body.appendChild(script);

    return () => {
      clearInterval(refreshCaptcha);
      document.body.removeChild(script);
    };
  }, [reCaptchaSiteKey]);

  return (
    <>
      <Input
        type="hidden"
        name="captchatoken"
        value={reCaptchaToken}
        inputRef={inputRef}
      />
      <LoadingOverlay
        active={!reCaptchaToken}
        spinner
        text="Loading..."
      ></LoadingOverlay>
    </>
  );
}
