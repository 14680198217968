import React, { useState } from "react";
import { Link as RouterLink, useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  Avatar,
  CssBaseline,
  TextField,
  Link,
  Grid,
  Typography,
  Container
} from "@material-ui/core";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import Alert from "@material-ui/lab/Alert";

import doApiRequest from "../ApiClient";
import SubmitButton from "../Components/SubmitButton";
import useStypeData from "../Registration/useStypeData";
import Captcha from "../Components/Captcha";

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  signupLink: {
    marginTop: theme.spacing(3)
  },
  alert: { marginTop: theme.spacing(1) }
}));

const SignUpForm = ({ stype, stypeData }) => {
  const classes = useStyles();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showSubjectMatch, setShowSubjectMatch] = useState(false);
  const [formData, setFormData] = useState();

  const signupSchema = yup.object().shape({
    email: yup
      .string()
      .email("Invalid email")
      .required("Email is required"),
    password: yup
      .string()
      .min(8, "Password must be at least 8 characters length")
      .required("Password is required"),
    confirmpassword: yup
      .string()
      .oneOf([yup.ref("password")], "Passwords must match")
      .required("Confirm Password is required")
  });

  const { register, handleSubmit, errors } = useForm({
    validationSchema: signupSchema
  });

  function submit(payload) {
    setLoading(true);

    const params = new URLSearchParams();
    params.append("email", payload.email);

    doApiRequest("pas/check-email/?" + params.toString()).then(
      ({ data: result }) => {
        if (result === "participant") {
          setError(
            <Typography>
              An account for this email exists.
              <Link component={RouterLink} to="/login">
                <br />
                Click here to log in.
              </Link>
            </Typography>
          );
          setLoading(false);
        } else if (result === "subject") {
          setError(null);
          setShowSubjectMatch(true);
          setFormData(payload);
          setLoading(false);
        } else {
          setError(null);
          createAccount(payload);
        }
      }
    );
  }

  function createAccount({ email, password, captchatoken }) {
    setLoading(true);
    const postData = { email, password, captchatoken };
    if (stype) {
      postData.stype = stype;
    }

    doApiRequest("pas/create-account/", {
      method: "POST",
      body: JSON.stringify(postData)
    }).then(result => {
      if (result.error) {
        setError(result.data.errors[0]);
      } else {
        setError(null);
        reloadPage();
      }
      setLoading(false);
    });
  }

  const reloadPage = () => {
    window.location.reload();
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5" gutterBottom>
          {stypeData?.label || "Create Account"}
        </Typography>
        {stypeData?.label && (
          <Typography>
            To enroll in this study, create an account and proceed to the
            enrollment form.
          </Typography>
        )}
        {showSubjectMatch ? (
          <Alert severity="info" className={classes.alert}>
            A participant record matches your email address. It will be linked
            to your account.
            <span onClick={() => createAccount(formData)}>
              <SubmitButton
                loading={loading}
                className={classes.submit}
                buttonText="Continue"
              />
            </span>
          </Alert>
        ) : (
          <form
            className={classes.form}
            onSubmit={handleSubmit(payload => submit(payload))}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  inputRef={register}
                  error={errors?.email ? true : false}
                  helperText={errors?.email?.message}
                  inputProps={{ autoCapitalize: "none" }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  inputRef={register}
                  error={errors?.password ? true : false}
                  helperText={errors?.password?.message}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  name="confirmpassword"
                  label="Confirm Password"
                  type="password"
                  id="confirmpassword"
                  autoComplete="current-password"
                  inputRef={register}
                  error={errors?.confirmpassword ? true : false}
                  helperText={errors?.confirmpassword?.message}
                />
              </Grid>
            </Grid>
            <SubmitButton
              loading={loading}
              className={classes.submit}
              buttonText="SIGN UP"
            />
            <Grid container justify="flex-end">
              <Grid item>
                <Link
                  component={RouterLink}
                  to={"/login" + (stype ? `/${stype}` : "")}
                  variant="body2"
                >
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid>
            {!loading && <Captcha inputRef={register} />}
          </form>
        )}
      </div>
      {error ? (
        <Alert severity="error" className={classes.alert}>
          {error}
        </Alert>
      ) : null}
    </Container>
  );
};

const NoEnroll = ({ stypeData }) => {
  const classes = useStyles();

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5" gutterBottom>
          {stypeData?.label}
        </Typography>
        {stypeData?.label && (
          <Typography>
            This study is no longer enrolling new participants. To view other
            recruiting studies click the link below.
          </Typography>
        )}
        <Grid container justify="flex-end" className={classes.signupLink}>
          <Grid item>
            <Link component={RouterLink} to={"/signup"} variant="body2">
              Sign Up for an account
            </Link>
          </Grid>
        </Grid>
      </div>
    </Container>
  );
};

export default function Signup() {
  const { stype } = useParams();
  const stypeData = useStypeData(stype);

  if (!stype || parseInt(stypeData?.status_id) === 1) {
    return <SignUpForm stype={stype} stypeData={stypeData} />;
  }

  return <NoEnroll stypeData={stypeData} />;
}
