import { useState, useEffect, useContext } from "react";
import { useDispatch } from "react-redux";
import { AssessmentContext } from "../../../AppContext/AssessmentContext";
import useQuestionState from "../useQuestionStateHook";

export default function useTextResponseHook(props, { schema, context }) {
  const { showErrors } = useContext(AssessmentContext);
  const dispatch = useDispatch();
  const {
    isValidResponse,
    response,
    userSkipped,
    saveResponse
  } = useQuestionState(props);
  const [value, setValue] = useState(response?.value || "");
  const [error, setError] = useState(null);

  useEffect(() => {
    let isSubscribed = true; // prevent rendering race condtions
    // use react suspence when it leaves experimental status
    // https://reactjs.org/docs/concurrent-mode-suspense.html#suspense-and-race-conditions
    schema
      .validate(value, { context })
      .then(() => {
        if (isSubscribed) setError(null);
      })
      .catch(error => {
        if (isSubscribed) setError(error);
      });
    return () => (isSubscribed = false);
  }, [context, schema, value]);

  function handleOnChange({ currentTarget: { value } }) {
    setValue(value);
  }

  function handleOnBlur() {
    dispatch(saveResponse({ value: error ? null : schema.cast(value) }));
  }

  const inTable = props.parentType === "TABLE";
  const flagMissing = inTable && showErrors && !isValidResponse;
  const variant = inTable ? "outlined" : "standard";
  const margin = inTable ? "dense" : "normal";

  return {
    value,
    flagMissing,
    error,
    userSkipped,
    handleOnChange,
    handleOnBlur,
    variant,
    margin
  };
}
