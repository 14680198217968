import React from "react";
import { useParams } from "react-router-dom";
import QueueListing from "./QueueListing";
import Queue from "./Queue";

export default function Queues(props) {
  let { id } = useParams();
  if (id) {
    return <Queue queueId={id} />;
  }
  return <QueueListing />;
}
