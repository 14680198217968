import React, { useState } from "react";
import { TextField, Box } from "@material-ui/core";

export default function SpecifyDetail({
  show,
  savedValue = "",
  saveResponseDetail
}) {
  const [value, setValue] = useState(savedValue);

  return show ? (
    <Box mt={-1} pl={6} display="flex">
      <TextField
        value={value}
        label="Specify"
        onChange={event => setValue(event.target.value)}
        onBlur={() => saveResponseDetail(value)}
        multiline
        style={{ width: "90%" }}
      />
    </Box>
  ) : null;
}
