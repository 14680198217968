import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { logout } from "./sessionSlice";
import doApiRequest from "../ApiClient";

export const fetchInstrument = createAsyncThunk(
  "instrument/fetchInstrument",
  async ({ instrumentId, token = null }) => {
    let url = `inst/details/${instrumentId}`;
    if (token) {
      url += `?t=${encodeURIComponent(token)}`;
    }

    const response = await doApiRequest(url, { method: "GET" });
    return response?.data;
  }
);

const instrumentSlice = createSlice({
  name: "instrument",
  initialState: {},
  reducers: {
    setSectionTimeOut: (state, action) => {
      const { instrumentId, sectionId, timerEndsAt } = action.payload;
      return {
        ...state,
        [instrumentId]: {
          ...state[instrumentId],
          object: {
            ...state[instrumentId].object,
            sections: state[instrumentId].object.sections.map(section => {
              if (section.section_id === sectionId) {
                return {
                  ...section,
                  timerEndsAt: timerEndsAt
                };
              }
              return section;
            })
          }
        }
      };
    }
  },
  extraReducers: {
    [fetchInstrument.pending]: (state, action) => {
      const instrumentId = action.meta.arg.instrumentId;
      state[instrumentId] = { isLoading: true, lastUpdated: Date.now() };
    },
    [fetchInstrument.fulfilled]: (state, action) => {
      const { instrument_id: instrumentId } = action.payload;
      state[instrumentId] = {
        object: action.payload,
        lastUpdated: Date.now(),
        isLoading: false
      };
    },
    [fetchInstrument.rejected]: (state, action) => {
      const instrumentId = action.meta.arg.instrumentId;
      state[instrumentId] = {
        error: action.payload,
        lastUpdated: Date.now(),
        isLoading: false
      };
    },
    [logout.fulfilled]: (state, action) => {
      state = {};
    }
  }
});

export const { setSectionTimeOut } = instrumentSlice.actions;

export default instrumentSlice.reducer;
