import React from "react";
import { Provider } from "react-redux";
import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import rootReducer from "../reducers";
import {
  evaluateConditionalRulesMiddleware,
  persistResponsesToDBMiddleware
} from "../reducers/assessmentSlice";

export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware().concat([
    persistResponsesToDBMiddleware,
    evaluateConditionalRulesMiddleware
  ])
});

export function StateProvider(props) {
  return <Provider store={store} {...props} />;
}
