import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
  Container,
  Typography,
  Grid,
  Card,
  CardHeader,
  Avatar,
  CardContent,
  Divider,
  Chip,
  Button,
  Box
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import CheckCircleOutlineRoundedIcon from "@material-ui/icons/CheckCircleOutlineRounded";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";

import { setQueueAvailability } from "../reducers/queueSlice";

const timezoneOffset = new Date().getTimezoneOffset() * 60000;
function formatDate(date) {
  if (!date) return null;
  const newDate = new Date(new Date(date).getTime() - timezoneOffset);
  return [
    newDate.toLocaleDateString("en-us", { dateStyle: "medium" }),
    newDate.toLocaleTimeString("en-us", { timeStyle: "short" })
  ].join(" ");
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    overflow: "hidden",
    padding: theme.spacing(1)
  },
  paper: {
    margin: `${theme.spacing(1)}px auto`,
    padding: theme.spacing(2)
  },
  queueType: {
    MuiChip: {
      root: {
        color: "white",
        backgroundColor: "#71c3c0"
      }
    }
  },
  CardContentRoot: {
    flexGrow: 1
  }
}));

export default function QueueListing() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    studyQueues = [],
    allQueues = [],
    isLoading,
    lastUpdated
  } = useSelector(state => state?.queue);

  const queueIsAvailable = queueId => allQueues?.[queueId].isAvailable;

  const studyQueuesWithItems = studyQueues.filter(({ queues = [] }) => {
    return queues.filter(queueIsAvailable).length > 0;
  });
  const isEmpty = studyQueuesWithItems.length === 0;

  useEffect(() => {
    for (const [queueId, queue] of Object.entries(allQueues)) {
      if (
        queue.isAvailable &&
        queue.end_date &&
        new Date(queue.end_date) < Date.now()
      ) {
        dispatch(setQueueAvailability(queueId, false));
      } else if (!queue.isAvailable) {
        dispatch(setQueueAvailability(queueId, true));
      }
      // api handle start date
    }
  }, [allQueues, dispatch]);

  return (
    <Container>
      <div className={classes.root}>
        <Typography variant="h6">Queues</Typography>
        {isLoading && !lastUpdated ? (
          <StudyQueuePaceholder />
        ) : isEmpty ? (
          <EmptyListing />
        ) : (
          studyQueuesWithItems.map(
            ({ study_id, study_label, hrrc, queues }) => (
              <StudyQueue
                key={study_id}
                label={study_label}
                hrrc={hrrc}
                queues={queues.filter(queueIsAvailable)}
              />
            )
          )
        )}
      </div>
    </Container>
  );
}

function StudyQueuePaceholder() {
  const classes = useStyles();
  return (
    <Card className={classes.paper}>
      <CardHeader
        avatar={
          <Skeleton animation="wave" variant="circle" width={40} height={40} />
        }
        title={
          <Skeleton
            animation="wave"
            height={10}
            width="30%"
            style={{ marginBottom: 6 }}
          />
        }
        subheader={<Skeleton animation="wave" height={10} width="10%" />}
      />
      <Divider />
      <CardContent>
        <div className={classes.CardContentRoot}>
          <Grid container spacing={2}>
            <Skeleton
              variant="rect"
              animation="wave"
              height={40}
              width="100%"
              style={{ marginTop: 6 }}
            />
          </Grid>
        </div>
      </CardContent>
    </Card>
  );
}

function StudyQueue({ label, hrrc, queues }) {
  const classes = useStyles();
  return (
    <Card className={classes.paper}>
      <CardHeader
        avatar={<Avatar>{label.trim().charAt(0)}</Avatar>}
        title={label}
        subheader={hrrc}
      />
      <Divider />
      <CardContent>
        <div className={classes.CardContentRoot}>
          <Grid container spacing={2}>
            {queues.map(queueId => {
              return (
                <Grid key={queueId} item xs={12}>
                  <QueueItem queueId={queueId} />
                </Grid>
              );
            })}
          </Grid>
        </div>
      </CardContent>
    </Card>
  );
}

function QueueItem({ queueId }) {
  const { queue_type_label: type, end_date, assessments } = useSelector(
    state => state?.queue?.allQueues?.[queueId] || {}
  );

  const assessmentCount = assessments.reduce(
    (count, { isComplete = false }) => {
      if (isComplete) {
        return count;
      }
      return count + 1;
    },
    0
  );

  return (
    <Grid container justify="center" alignItems="center" spacing={1}>
      <Grid item xs={6} sm={3}>
        <QueueTypeChip type={type} />
        {/* <Typography>{label}</Typography> */}
      </Grid>

      <Grid item xs={6} sm={3}>
        <Typography>{assessmentCount || "-"}</Typography>
        <Typography variant="caption" color="textSecondary">
          Assessments
        </Typography>
      </Grid>
      <Grid item xs={6} sm={3}>
        <Typography>{formatDate(end_date) || "Never"}</Typography>
        <Typography variant="caption" color="textSecondary">
          Expires
        </Typography>
      </Grid>
      <Grid item align="right" xs={6} sm={3}>
        <Button
          variant="outlined"
          endIcon={<KeyboardArrowRightIcon />}
          disabled={assessmentCount === 0}
          component={Link}
          to={`/queues/${queueId}`}
        >
          Start
        </Button>
      </Grid>
    </Grid>
  );
}

function QueueTypeChip({ type }) {
  const typeWithoutDash = type.toLowerCase().replace("-", "");

  const getChipStyle = type => {
    const defaultStyle = {
      textTransform: "uppercase",
      color: "white",
      backgroundColor: "#3f6e87"
    };

    switch (type) {
      case "onetime":
        return { ...defaultStyle, backgroundColor: "#71c3c0" };
      case "ongoing":
        return { ...defaultStyle, backgroundColor: "#f05a29" };
      case "recurring":
        return { ...defaultStyle, backgroundColor: "#d6df23" };
      default:
        return defaultStyle;
    }
  };

  return (
    <Chip
      size="small"
      label={typeWithoutDash}
      style={getChipStyle(typeWithoutDash)}
    />
  );
}

function EmptyListing() {
  return (
    <div>
      <Box style={{ textAlign: "center", paddingTop: "15px" }}>
        <CheckCircleOutlineRoundedIcon fontSize="large" />
        <Typography variant="h6">You're All Caught Up</Typography>
        <Typography variant="subtitle1" style={{ color: "gray" }}>
          Study questionnaires will show up here as they become available.
        </Typography>
      </Box>
    </div>
  );
}
