import React from "react";
import { makeStyles } from "@material-ui/core";
import DangerousTypography from "../../Components/DangerousTypography";

const useStyles = makeStyles(theme => ({
  label: {
    padding: "10px 20px 0px 20px",
    textAlign: "left"
  }
}));

export default function QuestionLabel({ children }) {
  const classes = useStyles();

  return (
    <DangerousTypography className={classes.label} gutterBottom>
      {children}
    </DangerousTypography>
  );
}
