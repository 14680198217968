import React, { forwardRef } from "react";
import useTextResponseDateTime from "./useTextResponseDateTimeHook";
import { KeyboardDatePicker } from "@material-ui/pickers";

function TextResponseDate(props, ref) {
  const {
    value,
    flagMissing,
    error,
    userSkipped,
    handleOnChange,
    format,
    views
  } = useTextResponseDateTime(props);

  return (
    <KeyboardDatePicker
      inputRef={ref}
      value={userSkipped ? null : value}
      error={flagMissing || error}
      helperText={error && "Please enter a valid date"}
      onChange={handleOnChange}
      autoOk={true}
      format={format}
      views={views}
      margin={"normal"}
      style={{ width: "150px" }}
    />
  );
}

export default forwardRef(TextResponseDate);
