import { combineReducers } from "redux";
import sessionReducer from "./sessionSlice";
import queueReducer from "./queueSlice";
import instrumentReducer from "./instrumentSlice";
import assessmentReducer from "./assessmentSlice";
import stypeReducer from "./stypeSlice";

export default combineReducers({
  session: sessionReducer,
  queue: queueReducer,
  instrument: instrumentReducer,
  assessment: assessmentReducer,
  stype: stypeReducer
});
