import React, { useState, forwardRef } from "react";
import { useDispatch } from "react-redux";
import useQuestionState from "./useQuestionStateHook";
import { TextField, makeStyles } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

const useStyles = makeStyles(theme => ({
  root: {
    paddingLeft: "15px",
    paddingRight: "15px",
    textAlign: "left"
  }
}));

function parseResponse(response) {
  if (response) return response.split("\n");
  return [];
}

function ListGeneration(props, ref) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { response, userSkipped, saveResponse } = useQuestionState(props);
  const [inputValue, setInputValue] = useState(() =>
    parseResponse(response?.value || "")
  );

  const handleBlur = e => {
    dispatch(saveResponse({ value: inputValue.join("\n") }));
  };

  return (
    <div className={classes.root}>
      <Autocomplete
        id={`${props.question_id}-${props.instance}`}
        multiple
        freeSolo
        options={[]}
        value={userSkipped ? [] : inputValue}
        onChange={(_e, value) => setInputValue(value)}
        renderInput={params => (
          <TextField
            inputRef={ref}
            {...params}
            margin="normal"
            variant="outlined"
            onBlur={handleBlur}
          />
        )}
      />
    </div>
  );
}

export default forwardRef(ListGeneration);
