import React from "react";

import { StateProvider } from "./StateContext";
import { ThemeProvider } from "./ThemeContext";
import { MuiPickersProvider } from "./MuiPickersContext";

export default function AppContext({ children }) {
  return (
    <StateProvider>
      <ThemeProvider>
        <MuiPickersProvider>{children}</MuiPickersProvider>
      </ThemeProvider>
    </StateProvider>
  );
}
