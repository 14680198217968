import React from "react";
import {
  Typography,
  makeStyles,
  Container,
  Button,
  useTheme,
  useMediaQuery,
  Paper
} from "@material-ui/core";
import logo from "../assets/logo.svg";

const useStyles = makeStyles(theme => ({
  containerBackground: {
    textAlign: "center",
    background: "#f4f4f4",
    [theme.breakpoints.up("sm")]: {
      marginTop: "20px",
      marginBottom: "20px",
      border: "solid #d5d8dc 1px"
    },
    [theme.breakpoints.down("xs")]: {
      minHeight: `calc(100vh - ${theme.mixins.toolbar.minHeight}px)`
    }
  },
  logo: {
    height: "min(5vw, 30px)",
    pointerEvents: "none",
    margin: "20px"
  },
  sectionDiv: {
    marginTop: "25px",
    marginBottom: "40px",
    padding: "0px 10px 0px 10px"
  },
  content: {
    padding: "40px"
  }
}));

export default function ProgressPrompt({
  queueProgress,
  handleUserPromptContinue,
  directQueue
}) {
  const classes = useStyles();
  const theme = useTheme();
  const compactMode = useMediaQuery(theme.breakpoints.down("xs"));
  const { assessments = [], itemIndex = 0 } = queueProgress;
  const remaining = assessments.length - itemIndex - 1;

  const message =
    remaining === 1
      ? `There is 1 questionnaire remaining in this queue.`
      : remaining > 1
      ? `There are ${remaining} questionnaires remaining in this queue.`
      : directQueue
      ? "There are no more questionnaires in this queue."
      : "There are no more questionnaires in this queue. Click Participant Portal above to access the Homepage or Logout to exit.";

  return (
    <Container
      maxWidth={compactMode ? false : "md"}
      disableGutters={compactMode}
    >
      <div className={classes.containerBackground}>
        <img src={logo} className={classes.logo} alt="logo" />
        <div className={classes.sectionDiv}>
          <Typography variant="h4" gutterBottom>
            Questionnaire Complete
          </Typography>
          <hr />
          <div className={classes.content}>
            <Paper square={compactMode} className={classes.content}>
              {message}
            </Paper>
          </div>
          {remaining > 0 && (
            <Button
              variant="contained"
              color="primary"
              onClick={handleUserPromptContinue}
            >
              Continue
            </Button>
          )}
        </div>
      </div>
    </Container>
  );
}
