import React from "react";
import { Container, makeStyles, Paper } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(5),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "40px"
  }
}));

export default function Message({ children }) {
  const classes = useStyles();

  return (
    <Container component="main" maxWidth="sm">
      <Paper className={classes.paper}>{children}</Paper>
    </Container>
  );
}
