import React, { forwardRef } from "react";
import useTextResponse from "./useTextResponseHook";
import { TextField } from "@material-ui/core";

function TextResponseDefault(
  { fullWidth = false, multiline = false, validation, ...props },
  ref
) {
  const {
    value,
    flagMissing,
    error,
    userSkipped,
    handleOnChange,
    handleOnBlur,
    variant,
    margin
  } = useTextResponse(props, validation);

  return (
    <TextField
      inputRef={ref}
      value={userSkipped ? "" : value}
      error={flagMissing || Boolean(error)}
      helperText={error?.message}
      onChange={handleOnChange}
      onBlur={handleOnBlur}
      variant={variant}
      margin={margin}
      fullWidth={fullWidth}
      multiline={multiline}
    />
  );
}

export default forwardRef(TextResponseDefault);
