import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { Button, CircularProgress } from "@material-ui/core";
import { green } from "@material-ui/core/colors";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    alignItems: "center"
  },
  wrapper: {
    position: "relative",
    width: "100%"
  },
  button: {
    margin: theme.spacing(3, 0, 2)
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -8,
    marginLeft: -15
  },
  buttonSuccess: {
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700]
    }
  }
}));

export default function SubmitButton({ children, loading, success }) {
  const classes = useStyles();

  const buttonClassname = clsx({
    [classes.button]: true,
    [classes.buttonSuccess]: success
  });

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <Button
          type="submit"
          color="primary"
          variant="contained"
          className={buttonClassname}
          disabled={loading || success}
          fullWidth
        >
          {children}
        </Button>
        {loading && (
          <CircularProgress size={24} className={classes.buttonProgress} />
        )}
      </div>
    </div>
  );
}
