import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";

import AppNavBar from "./AppNavBar";
import AppNavDrawer from "./AppNavDrawer";

const useStyles = makeStyles(theme => ({
  root: {},
  main: {
    display: "flex",
    minHeight: "100vh"
  },
  toolbar: theme.mixins.toolbar,
  view: {
    flexGrow: 1,
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${theme.drawerWidth}px)`,
      marginLeft: theme.drawerWidth
    },
    background: theme.viewBackgroundColor
  }
}));

export default function AppNav({ fullInterface, children }) {
  const classes = useStyles();
  const [drawerOpen, setdrawerOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setdrawerOpen(!drawerOpen);
  };

  return (
    <Fragment>
      <AppNavBar
        fullInterface={fullInterface}
        drawerOpen={drawerOpen}
        handleDrawerToggle={handleDrawerToggle}
      />
      {fullInterface ? (
        <Fragment>
          <AppNavDrawer
            drawerOpen={drawerOpen}
            handleDrawerToggle={handleDrawerToggle}
          />
          <div className={classes.main}>
            <div className={classes.view}>
              <div className={classes.toolbar} />
              {children}
            </div>
          </div>
        </Fragment>
      ) : (
        <div>
          <div className={classes.toolbar} />
          {children}
        </div>
      )}
    </Fragment>
  );
}
