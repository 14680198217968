import React from "react";
import ErrorIcon from "@material-ui/icons/Error";
import { Box, Typography, Link } from "@material-ui/core";

export default function ErrorMessage({ error }) {
  const errorString =
    typeof error === "object" && error !== null ? JSON.stringify(error) : error;
  const adminEmail = process.env.COINS_EMAIL || "dev@email.org";
  const subject = "Participant Portal Error";
  const body = `Please leave a brief description of the issue you experienced below:
%0D%0A %0D%0A %0D%0A
--------------------- Error Details (For Developers) ---------------------
%0D%0A
${errorString}`;

  return (
    <Box style={{ textAlign: "center", paddingTop: "50px" }}>
      <ErrorIcon color="disabled" fontSize="large" />
      <Typography variant="h6" style={{ color: "black" }}>
        Oops! Something went wrong.
      </Typography>
      <Typography variant="subtitle1" style={{ color: "gray" }}>
        If this issue persists, please contact{" "}
        <Link href={`mailto:${adminEmail}?subject=${subject}&body=${body}`}>
          {adminEmail}
        </Link>
      </Typography>
    </Box>
  );
}
