import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Container,
  makeStyles,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableFooter,
  TableRow,
  TableCell,
  TableSortLabel
} from "@material-ui/core";
import moment from "moment";
import doApiRequest from "../ApiClient";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3)
  },
  header: {
    paddingBottom: theme.spacing(4)
  },
  tableHeader: {
    paddingBottom: theme.spacing(2)
  },
  footer: {
    borderBottom: 0
  },
  tableCard: {
    padding: theme.spacing(4)
  }
}));

export default function Radiology() {
  const classes = useStyles();
  const [radLetters, setRadLetters] = useState([]);
  const [sortDirection, setSortDirection] = useState("desc");

  useEffect(() => {
    doApiRequest("rad/rad-letters").then(({ data, error }) => {
      setRadLetters(data);
    });
  }, []);

  function sortFunction(a, b) {
    return sortDirection === "desc"
      ? moment(b.scan_date) - moment(a.scan_date)
      : moment(a.scan_date) - moment(b.scan_date);
  }

  function changeSort() {
    setSortDirection(sortDirection === "asc" ? "desc" : "asc");
  }

  return (
    <Container>
      <div className={classes.root}>
        <Typography variant="h4" className={classes.header} align="center">
          Radiology
        </Typography>
        <Card className={classes.tableCard}>
          <Typography variant="h6" className={classes.tableHeader}>
            Download Radiology Letters
          </Typography>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>
                  <TableSortLabel
                    active={true}
                    direction={sortDirection}
                    onClick={changeSort}
                  >
                    Scan Date
                  </TableSortLabel>
                </TableCell>
                <TableCell align="right">&nbsp;</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {radLetters.sort(sortFunction).map(row => {
                return (
                  <TableRow hover key={row.session_scan_id}>
                    <TableCell>
                      {moment(row.scan_date).format("MM/DD/YYYY")}
                    </TableCell>
                    <TableCell align="right">
                      <Button
                        target="_blank"
                        rel="noopener"
                        href={`/p3api/rad/rad-letter/${row.session_scan_id}`}
                      >
                        Download
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell colSpan={2} align="right" className={classes.footer}>
                  {radLetters.length} letter(s)
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </Card>
      </div>
    </Container>
  );
}
