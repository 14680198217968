import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Link as RouterLink, useParams } from "react-router-dom";
import doApiRequest from "../ApiClient";
import * as yup from "yup";
import {
  Avatar,
  CssBaseline,
  TextField,
  Grid,
  Typography,
  Container,
  Link
} from "@material-ui/core";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import { makeStyles } from "@material-ui/core/styles";
import SubmitButton from "./SubmitButton";
import { Alert } from "@material-ui/lab";
import Captcha from "../Components/Captcha";

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  errorText: {
    display: "inline"
  }
}));

export default function Reset() {
  const classes = useStyles();
  const { code, stype } = useParams();

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const resetSchema = yup.object().shape({
    password: yup
      .string()
      .min(8, "Password must be at least 8 characters length")
      .required("Password is required"),
    confirmpassword: yup
      .string()
      .oneOf([yup.ref("password")], "Passwords must match")
      .required("Confirm Password is required")
  });

  const { register, handleSubmit, errors } = useForm({
    validationSchema: resetSchema
  });

  async function resetPassword({ password, captchatoken }) {
    setLoading(true);
    const { error, data } = await doApiRequest(`pas/reset/`, {
      method: "POST",
      body: JSON.stringify({ password, code, captchatoken })
    });
    setLoading(false);
    setError(error ? data?.errors : false);
    setSuccess(!error);
  }

  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOpenIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Change Password
        </Typography>
        <form
          className={classes.form}
          noValidate
          onSubmit={handleSubmit(payload => resetPassword(payload))}
        >
          {!success ? (
            <>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    type="password"
                    variant="outlined"
                    required
                    fullWidth
                    id="password"
                    label="Password"
                    name="password"
                    autoComplete="password"
                    inputRef={register}
                    error={errors?.password ? true : false}
                    helperText={errors?.password?.message}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    type="password"
                    variant="outlined"
                    required
                    fullWidth
                    id="confirmpassword"
                    label="Retype Password"
                    name="confirmpassword"
                    autoComplete="password"
                    inputRef={register}
                    error={errors?.confirmpassword ? true : false}
                    helperText={errors?.confirmpassword?.message}
                  />
                </Grid>
              </Grid>
              <SubmitButton loading={loading}>Reset</SubmitButton>
            </>
          ) : (
            <Alert severity="success" className={classes.alert}>
              <span className={classes.errorText}>
                Your password has been rest successfully. Please{" "}
                <Link
                  component={RouterLink}
                  to={"/login" + (stype ? `/${stype}` : "")}
                >
                  click here to login.
                </Link>
              </span>
            </Alert>
          )}
          {error ? (
            <Alert severity="error" className={classes.alert}>
              <span className={classes.errorText}>{error}</span>
            </Alert>
          ) : null}
          {!loading && <Captcha inputRef={register} />}
        </form>
      </div>
    </Container>
  );
}
