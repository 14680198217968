import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { fetchInstrument } from "./instrumentSlice";
import { startAssessment, completeAssessment } from "./assessmentSlice";
import { logout } from "./sessionSlice";
import doApiRequest from "../ApiClient";

function pollyfillApiResponse(userQueues) {
  return userQueues.reduce(
    (a, studyQueue) => {
      a.allQueues = {
        ...a.allQueues,
        ...studyQueue.queues.reduce((a, queue) => {
          a[queue.queue_id] = {
            ...queue,
            study_id: studyQueue.study_id,
            isAvailable: true
          };
          return a;
        }, {})
      };

      a.studyQueues.push({
        ...studyQueue,
        queues: studyQueue.queues.map(({ queue_id: id }) => id)
      });
      return a;
    },
    { allQueues: {}, studyQueues: [] }
  );
}

export const fetchUserQueues = createAsyncThunk(
  "queue/fetchUserQueues",
  async () => {
    const response = await doApiRequest("queue/", { method: "GET" });
    return pollyfillApiResponse(response?.data);
  }
);

export const startQueue = createAsyncThunk(
  "queue/startQueue",
  async (queueId, { dispatch, getState, rejectWithValue }) => {
    const { queue, instrument } = getState();
    const queueToStart = queue.allQueues?.[queueId];

    if (!queueToStart) {
      return rejectWithValue(`queue id: ${queueId} not found`);
    }

    // prefetch all instruments
    queueToStart.assessments.forEach(({ instrument_id: instrumentId }) => {
      if (!(instrumentId in instrument)) {
        dispatch(fetchInstrument({ instrumentId }));
      }
    });

    return { queueId };
  }
);

export const addQueue = createAsyncThunk(
  "queue/addQueue",
  async (queueId, { rejectWithValue }) => {
    const response = await doApiRequest(`queue/${queueId}`, { method: "GET" });
    if (response.error) {
      return rejectWithValue(response.data.errors[0]);
    }

    return pollyfillApiResponse(response?.data);
  }
);

const generateInitialState = () => {
  return {
    studyQueues: [],
    allQueues: {},
    isLoading: false,
    lastUpdated: null
  };
};

const queueSlice = createSlice({
  name: "queue",
  initialState: generateInitialState(),
  reducers: {
    completeQueue: (state, action) => {
      const queueId = action.payload;

      const { queue_type_label: type } = state.allQueues?.[queueId] || {};

      if (type === "One-time" || type === "Recurring") {
        if (state.allQueues[queueId]) {
          state.allQueues[queueId].isComplete = true;
        }
      }
    },
    setQueueAvailability: (state, action) => {
      const { queueId, isAvailable } = action.payload;
      if (state?.allQueues[queueId]) {
        state.allQueues[queueId].isAvailable = isAvailable;
      }
    }
  },
  extraReducers: {
    [fetchUserQueues.pending]: (state, action) => {
      state.isLoading = true;
    },
    [fetchUserQueues.fulfilled]: (state, action) => {
      const { studyQueues, allQueues } = action.payload;
      state.isLoading = false;
      state.studyQueues = studyQueues;
      state.allQueues = allQueues;
      state.lastUpdated = Date.now();
    },
    [addQueue.pending]: (state, action) => {
      state.isLoading = true;
    },
    [addQueue.fulfilled]: (state, action) => {
      const { studyQueues, allQueues } = action.payload;
      state.isLoading = false;
      state.studyQueues = [...state.studyQueues, ...studyQueues];
      state.allQueues = { ...state.allQueues, ...allQueues };
      if (!state.lastUpdated) {
        state.lastUpdated = Date.now();
      }
    },
    [startAssessment.fulfilled]: (state, action) => {
      const { queueId, queueInstrumentId } = action.meta.arg;
      const assessmentId = action.payload;

      const assessments = state.allQueues?.[queueId]?.assessments || [];
      const item = assessments.find(
        item => item.queue_instrument_id === queueInstrumentId
      );

      if (item) {
        item.assessment_id = assessmentId;
      }
    },
    [completeAssessment.fulfilled]: (state, action) => {
      const { queueId, assessmentId } = action.meta.arg;

      if (state.allQueues?.[queueId]?.assessments) {
        const itemIndex = state.allQueues[queueId].assessments.findIndex(
          ({ assessment_id }) => assessment_id === assessmentId
        );
        if (itemIndex !== -1) {
          state.allQueues[queueId].assessments[itemIndex].isComplete = true;
        }
      }
    },
    [logout.fulfilled]: (state, action) => {
      state = generateInitialState();
    }
  }
});

export const { completeQueue, setQueueAvailability } = queueSlice.actions;

export default queueSlice.reducer;
