import React, { useState, useEffect } from "react";
import {
  makeStyles,
  Card,
  Container,
  Grid,
  TableRow,
  TableCell,
  TextField,
  Typography
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { KeyboardDatePicker } from "@material-ui/pickers";
import moment from "moment";
import DataTable from "../Components/DataTable";
import StudyHover from "../Components/StudyHover";
import doApiRequest from "../ApiClient";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3)
  },
  filters: {
    paddingBottom: theme.spacing(2)
  },
  header: {
    paddingBottom: theme.spacing(4)
  },
  totalCell: {
    borderBottom: 0,
    color: "black",
    fontSize: "0.875rem"
  },
  tableCard: {
    padding: theme.spacing(4)
  },
  noData: {
    padding: theme.spacing(4)
  }
}));

const usdFormat = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD"
});

const columns = [
  {
    key: "payment_date",
    name: "Payment Date",
    displayFunc: row => moment(row.payment_date).format("MM/DD/YYYY"),
    compareFunc: (a, b) => moment(a.payment_date) - moment(b.payment_date)
  },
  {
    key: "study_info",
    name: "Study",
    displayFunc: row => {
      return (
        <StudyHover irb={row.irb_number} label={row.study_label}></StudyHover>
      );
    },
    compareFunc: (a, b) => a.irb_number.localeCompare(b.irb_number)
  },
  {
    key: "type_label",
    name: "Payment Type"
  },
  {
    key: "amount",
    name: "Amount",
    displayFunc: row => usdFormat.format(row.total_amount),
    compareFunc: (a, b) => a.total_amount - a.total_amount,
    align: "right"
  }
];

export default function Payments() {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [payments, setPayments] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [study, setStudy] = useState(null);
  const [filteredPayments, setFilteredPayments] = useState(() => payments);

  useEffect(() => {
    setLoading(true);
    doApiRequest("payments/payments").then(({ data, error }) => {
      setPayments(data);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    setFilteredPayments(
      payments.filter(payment => {
        if (startDate && moment(payment.payment_date) < startDate) {
          return false;
        }

        if (endDate && moment(payment.payment_date) > endDate) {
          return false;
        }

        if (study && payment.study_label !== study) {
          return false;
        }

        return true;
      })
    );
  }, [payments, startDate, endDate, study]);

  function handleStudyChange(event, value) {
    setStudy(value);
  }

  return (
    <Container>
      <div className={classes.root}>
        <Typography variant="h4" className={classes.header} align="center">
          Payments
        </Typography>
        <Card className={classes.tableCard}>
          <Grid
            container
            spacing={3}
            className={classes.filters}
            justify="space-evenly"
            alignItems="center"
          >
            <Grid item xs={12} md={3}>
              <KeyboardDatePicker
                value={startDate}
                onChange={setStartDate}
                label="Start Date"
                format="MM/DD/YYYY"
                autoOk={true}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <KeyboardDatePicker
                value={endDate}
                onChange={setEndDate}
                label="End Date"
                format="MM/DD/YYYY"
                autoOk={true}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Autocomplete
                options={[
                  ...new Set(payments.map(payment => payment.study_label))
                ]}
                renderInput={params => (
                  <TextField {...params} label="Study" variant="outlined" />
                )}
                value={study}
                onChange={handleStudyChange}
              />
            </Grid>
          </Grid>
          {loading || filteredPayments.length > 0 ? (
            <DataTable
              data={filteredPayments}
              columns={columns}
              id_key="id"
              postPaginationFooterRows={[
                (_sorted, displayed) => (
                  <TableRow>
                    <TableCell
                      colSpan={columns.length}
                      align="right"
                      className={classes.totalCell}
                    >
                      Total for all rows:&nbsp;
                      {usdFormat.format(
                        displayed.reduce((sum, next) => {
                          return sum + next.total_amount;
                        }, 0)
                      )}
                    </TableCell>
                  </TableRow>
                )
              ]}
            />
          ) : (
            <Typography align="center" className={classes.noData}>
              No Payments Found
            </Typography>
          )}
        </Card>
      </div>
    </Container>
  );
}
