import React, { useContext, forwardRef, Children } from "react";
import { Grid } from "@material-ui/core";

import { AssessmentContext } from "../../AppContext/AssessmentContext";
import QuestionContainer from "./Components/QuestionContainer";
import Media from "./Components/Media";
import QuestionLabel from "./Components/QuestionLabel";
import TextResponse from "./TextResponse";
import {
  CannedResponseRadio,
  CannedResponseCheckbox,
  CannedResponseSelect
} from "./CannedResponse";
import VisualAnalogScale from "./VisualAnalogScale";
import ListGeneration from "./ListGeneration";

export function QuestionCard({
  compactMode,
  label,
  media,
  questionIds,
  userSkippable,
  flagIfMissing,
  children
}) {
  const { debugMode } = useContext(AssessmentContext);
  const mediaTop = media?.position === 1;
  const mediaBottom = media?.position === -1;

  if (Children.count(children) === 0) {
    return null;
  }

  return (
    <QuestionContainer
      compact={compactMode}
      questionIds={questionIds}
      userSkippable={userSkippable}
      flagIfMissing={flagIfMissing}
    >
      {mediaTop && <Media {...media} />}
      <QuestionLabel>
        {debugMode ? `[${questionIds}] ${label}` : label}
      </QuestionLabel>
      {Children.count(children) === 1 ? (
        children
      ) : (
        <Grid container direction="column">
          {Children.map(children, child => (
            <Grid item key={child.key}>
              {child}
            </Grid>
          ))}
        </Grid>
      )}

      {mediaBottom && <Media {...media} />}
    </QuestionContainer>
  );
}

function Question(props, ref) {
  const { question_id: questionId, question_type: type } = props;

  switch (type) {
    case "TEXT":
      return <TextResponse ref={ref} {...props} />;
    case "CANNED_RESPONSE_RADIO":
      return <CannedResponseRadio ref={ref} {...props} />;
    case "CANNED_RESPONSE_CHECKBOX":
      return <CannedResponseCheckbox ref={ref} {...props} />;
    case "CANNED_RESPONSE_SELECT":
      return <CannedResponseSelect ref={ref} {...props} />;
    case "VAS":
      return <VisualAnalogScale ref={ref} {...props} />;
    case "LIST_GENERATION":
      return <ListGeneration ref={ref} {...props} />;
    default:
      return (
        <div key={questionId}>
          Unknown Question Type: {type} Question id: {questionId}
        </div>
      );
  }
}

export default forwardRef(Question);
