import React, { useRef } from "react";
import useInstrumentState from "./useInstrumentStateHook";
import { makeStyles } from "@material-ui/core/styles";
import AssessmentContext from "../AppContext/AssessmentContext";
import {
  Container,
  Grid,
  Divider,
  Typography,
  Button,
  useTheme,
  useMediaQuery,
  LinearProgress
} from "@material-ui/core";
import logo from "../assets/logo.svg";

import DangerousTypography from "./Components/DangerousTypography";
import ErrorMessage from "../Components/ErrorMessage";
import LikertGrid from "./Components/LikertGrid";
import { TableGrid, TableGridRow } from "./Components/TableGrid";
import Question, { QuestionCard } from "./Question";
import { SectionTimer } from "./Components/TimerSection";

const useStyles = makeStyles(theme => ({
  containerBackground: {
    textAlign: "center",
    background: "#f4f4f4",
    [theme.breakpoints.up("sm")]: {
      marginTop: "20px",
      marginBottom: "20px",
      border: "solid #d5d8dc 1px"
    },
    [theme.breakpoints.down("xs")]: {
      minHeight: `calc(100vh - ${theme.mixins.toolbar.minHeight}px)`
    }
  },
  logo: {
    height: "min(5vw, 30px)",
    pointerEvents: "none",
    margin: "20px"
  },
  sectionDiv: {
    marginTop: "25px",
    marginBottom: "30px",
    padding: "0px 10px 0px 10px"
  },
  gridItem: {
    paddingTop: "15px",
    paddingBottom: "15px",
    "&:empty": { display: "none" }
  },
  navDiv: {
    paddingTop: "15px",
    paddingBottom: "15px",
    paddingLeft: "25px",
    paddingRight: "25px"
  },
  footerDiv: {
    paddingTop: "15px",
    paddingBottom: "15px"
  },
  alignSecText: {
    textAlign: "left",
    padding: "10px 25px 0px 25px"
  },
  timedOutStyles: {
    position: "relative",
    maxHeight: "60vh",
    minHeight: "300px"
  }
}));

// create an array of instances 1-n for a given maxInstances
const arrayFrom = maxInstances => [...Array(maxInstances + 1).keys()].slice(1);

export default function Instrument({ debugMode = false, ...props }) {
  const theme = useTheme();
  const classes = useStyles();
  const compactMode = useMediaQuery(theme.breakpoints.down("xs"));
  const timedFrameRef = useRef();

  const {
    error,
    isLoading,
    showErrors,
    assessmentId,
    instrumentId,
    instrument,
    section,
    page,
    hideCondSkipped,
    skippedQIDs,
    questions,
    questionIdRefs,
    isFirstPage,
    nextPage,
    showPreviousPage,
    previousPage
  } = useInstrumentState(props);

  if (error) {
    return <ErrorMessage error={error} />;
  }

  if (isLoading) {
    return <LinearProgress />;
  }

  function getQuestionWithCard({
    question_id,
    sa_label,
    label,
    media,
    is_required,
    max_instances
  }) {
    return (
      <QuestionCard
        compactMode={compactMode}
        label={sa_label || label}
        media={media}
        questionIds={[question_id]}
        userSkippable={!is_required}
        flagIfMissing={true}
      >
        {arrayFrom(max_instances).map(instance => (
          <Question
            ref={questionIdRefs[`${question_id}-${instance}`]}
            key={`${question_id}-${instance}`}
            {...questions[question_id]}
            instance={instance}
          />
        ))}
      </QuestionCard>
    );
  }

  return (
    <AssessmentContext
      value={{
        assessmentId,
        instrumentId,
        showErrors,
        hideCondSkipped,
        skippedQIDs,
        debugMode
      }}
    >
      <Container
        maxWidth={compactMode ? false : "md"}
        disableGutters={compactMode}
      >
        <div className={classes.containerBackground}>
          {/* Header */}
          <img src={logo} className={classes.logo} alt="logo" />
          <div className={classes.sectionDiv}>
            <Typography variant="h4" gutterBottom>
              {instrument.sa_label || instrument.label}
            </Typography>
            <Typography
              variant="h6"
              gutterBottom
              className={classes.alignSecText}
            >
              {section.sa_label}
            </Typography>
            <Divider variant="middle" />
            <DangerousTypography className={classes.alignSecText} gutterBottom>
              {section.sa_desc}
            </DangerousTypography>
          </div>

          {/* Body */}
          {section.time_limit != null && (
            <SectionTimer
              instrumentId={instrumentId}
              assessmentId={assessmentId}
              section={section}
              frameRef={timedFrameRef}
            />
          )}

          <div
            ref={timedFrameRef}
            className={
              Date.now() > section.timerEndsAt ? classes.timedOutStyles : ""
            }
          >
            <Grid>
              {page.map(item => {
                if (typeof item === "string") {
                  /* Single Question */
                  const question = questions[item];

                  if (!question || (question && question.is_sa_hidden)) {
                    return null;
                  }

                  return (
                    <Grid item key={item} className={classes.gridItem}>
                      {getQuestionWithCard(question)}
                    </Grid>
                  );
                } else if (typeof item === "object") {
                  /* Group of Questions */
                  const {
                    question_type: type,
                    questions: questionsInObj
                  } = item;
                  /* LIKERT GRID */
                  if (type === "LIKERT_GRID") {
                    if (compactMode) {
                      return questionsInObj.reduce((acc, questionId) => {
                        const question = questions[questionId];

                        if (!question.is_sa_hidden) {
                          acc.push(
                            <Grid
                              item
                              key={questionId}
                              className={classes.gridItem}
                            >
                              {getQuestionWithCard(question)}
                            </Grid>
                          );
                        }
                        return acc;
                      }, []);
                    } else {
                      const questionIds = [];
                      const LikertGridQuestions = questionsInObj.reduce(
                        (acc, questionId) => {
                          const {
                            max_instances: maxInstances,
                            is_sa_hidden: hidden,
                            ...question
                          } = questions[questionId];

                          questionIds.push(questionId);

                          if (question.is_sa_hidden) {
                            return acc;
                          }

                          acc.push(
                            ...arrayFrom(maxInstances).map(instance => (
                              <Question
                                ref={
                                  questionIdRefs[`${questionId}-${instance}`]
                                }
                                key={`${questionId}-${instance}`}
                                {...question}
                                instance={instance}
                                parentType={type}
                              />
                            ))
                          );

                          return acc;
                        },
                        []
                      );

                      if (LikertGridQuestions.length === 0) {
                        return null;
                      }

                      const {
                        sa_label,
                        label,
                        media,
                        labels: columnLabels
                      } = item;
                      return (
                        <Grid
                          item
                          key={questionsInObj.join("-")}
                          className={classes.gridItem}
                        >
                          <QuestionCard
                            compactMode={compactMode}
                            label={sa_label || label}
                            media={media}
                            questionIds={questionIds}
                          >
                            <LikertGrid columnLabels={columnLabels}>
                              {LikertGridQuestions}
                            </LikertGrid>
                          </QuestionCard>
                        </Grid>
                      );
                    }
                  } else if (type === "TABLE") {
                    /* TABLE GRID */
                    const {
                      sa_label,
                      label,
                      media,
                      column_labels: columnLabels,
                      row_labels: rowLabels
                    } = item;

                    const questionIds = [];
                    const tableGridQuestions = questionsInObj.reduce(
                      (acc, row, rowIndex) => {
                        let rowHasQuestion = false;

                        const questionsInRow = row.map(questionId => {
                          const {
                            max_instances: maxInstances,
                            is_sa_hidden: hidden,
                            ...question
                          } = questions[questionId];

                          questionIds.push(questionId);

                          if (hidden) {
                            return null;
                          }

                          const QuestionInstances = arrayFrom(
                            maxInstances
                          ).map(instance => (
                            <Question
                              ref={questionIdRefs[`${questionId}-${instance}`]}
                              key={`${questionId}-${instance}`}
                              {...question}
                              instance={instance}
                              parentType={type}
                            />
                          ));

                          if (QuestionInstances.length !== 0) {
                            rowHasQuestion = true;
                          } else {
                            return null;
                          }

                          return QuestionInstances.length > 1 ? (
                            <Grid>{QuestionInstances}</Grid>
                          ) : (
                            QuestionInstances?.[0]
                          );
                        });

                        if (rowHasQuestion) {
                          acc.push({
                            rowIndex,
                            QuestionComponents: questionsInRow
                          });
                        }

                        return acc;
                      },
                      []
                    );

                    if (tableGridQuestions.length === 0) {
                      return null;
                    }

                    return (
                      <Grid
                        item
                        key={questionsInObj.join("-")}
                        className={classes.gridItem}
                      >
                        <QuestionCard
                          compactMode={compactMode}
                          label={sa_label || label}
                          media={media}
                          questionIds={questionIds}
                        >
                          <TableGrid columnLabels={columnLabels}>
                            {tableGridQuestions.map(
                              ({ rowIndex, QuestionComponents }) => (
                                <TableGridRow
                                  key={`${rowLabels[rowIndex]}-${rowIndex}`}
                                  label={rowLabels[rowIndex]}
                                >
                                  {QuestionComponents}
                                </TableGridRow>
                              )
                            )}
                          </TableGrid>
                        </QuestionCard>
                      </Grid>
                    );
                  }
                }
                return null;
              })}
            </Grid>
          </div>

          {/* Nav Buttons */}
          <div className={classes.navDiv}>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <Grid item>
                {showPreviousPage && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={previousPage}
                    disabled={isFirstPage}
                  >
                    Previous
                  </Button>
                )}
              </Grid>
              <Grid item>
                <Button variant="contained" color="primary" onClick={nextPage}>
                  Next
                </Button>
              </Grid>
            </Grid>
          </div>

          {/* Footer */}
          {instrument.cr_notice && (
            <div className={classes.footerDiv}>
              <Divider variant="middle" />
              <DangerousTypography gutterBottom>
                {instrument.cr_notice}
              </DangerousTypography>
            </div>
          )}
        </div>
      </Container>
    </AssessmentContext>
  );
}
