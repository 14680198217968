import { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import useQuestionState from "../useQuestionStateHook";

function initState(options, response) {
  return options.reduce((obj, option) => {
    // fetch question options
    const {
      response_id: responseId,
      value,
      label,
      sort_order: sortOrder,
      specify_detail: isSpecifyDetail
    } = option;

    // get saved responses
    const { value: savedValue, detail: savedDetail } =
      response?.[responseId] || {};

    obj[responseId] = {
      checked: typeof savedValue !== "undefined",
      value,
      label,
      sortOrder,
      isSpecifyDetail: Boolean(isSpecifyDetail),
      detail: savedDetail
    };
    return obj;
  }, {});
}

export default function useCannedResponseStateHook({ options, ...props }) {
  const initStateEventRef = useRef(true);
  const { question_type: questionType } = props;
  const {
    isValidResponse,
    response,
    userSkipped,
    saveResponse
  } = useQuestionState(props);
  const [state, setState] = useState(() => initState(options, response));
  const dispatch = useDispatch();

  // selected response options
  const selectedResponses = Object.entries(state).filter(
    ([, { checked }]) => checked
  );

  // get counts
  const totalCount = Object.keys(state).length;
  const checkedCount = selectedResponses.length;

  // select all / deslect all response status
  let selectAllStatus = "SOME";
  if (checkedCount === 0) {
    selectAllStatus = "NONE";
  } else if (checkedCount === totalCount) {
    selectAllStatus = "ALL";
  }

  // toggle all response options (select all / deslect all)
  function toggleSelectAll() {
    const checked = checkedCount !== totalCount;
    setState(
      Object.entries(state).reduce(
        (obj, [responseId, response]) => ({
          ...obj,
          [responseId]: {
            ...response,
            checked
          }
        }),
        {}
      )
    );
  }

  // toggle select status of a single response option (checkbox style)
  function toggleSelect(responseId) {
    setState({
      ...state,
      [responseId]: {
        ...state?.[responseId],
        checked: !state?.[responseId]?.checked
      }
    });
  }

  // select a single respose option (radio style)
  function setSelected(clickedResponseId) {
    setState(
      Object.entries(state).reduce(
        (obj, [responseId, response]) => ({
          ...obj,
          [responseId]: {
            ...response,
            checked: responseId === clickedResponseId
          }
        }),
        {}
      )
    );
  }

  // response detail
  function setDetail(responseId, detail) {
    setState({
      ...state,
      [responseId]: {
        ...state?.[responseId],
        detail
      }
    });
  }

  // save response in state
  useEffect(() => {
    // dont trigger a save on the initial render
    if (initStateEventRef.current) {
      initStateEventRef.current = false;
      return;
    }
    dispatch(
      saveResponse(
        selectedResponses.reduce(
          (
            acc,
            [responseId, { value, sortOrder, isSpecifyDetail, detail = "" }]
          ) => ({
            // strip extra state variables (checked, etc)
            // include `sort_order` only for checkbox questions
            // include `detail` only for specify detail response options
            ...acc,
            [responseId]: {
              value,
              ...(questionType === "CANNED_RESPONSE_CHECKBOX" && {
                sort_order: sortOrder
              }),
              ...(isSpecifyDetail && { detail })
            }
          }),
          {}
        )
      )
    );
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [
    initStateEventRef,
    dispatch,
    saveResponse,
    questionType,
    JSON.stringify(selectedResponses)
  ]);
  /* eslint-enable react-hooks/exhaustive-deps */

  return {
    isValidResponse,
    state,
    selectedResponses,
    userSkipped,
    toggleSelect,
    setSelected,
    setDetail,
    selectAllStatus,
    toggleSelectAll
  };
}
