import { useState, useEffect, useMemo, createRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  initAssessment,
  evaluateMissingResponses,
  completeAssessment
} from "../reducers/assessmentSlice";

// create an array of instances 1-n for a given maxInstances
const arrayFrom = maxInstances => [...Array(maxInstances + 1).keys()].slice(1);

export default function useInstrumentState({
  queueId,
  instrumentId,
  queueInstrumentId,
  assessmentId: existingAssessmentId,
  startAtQuestion = false,
  handleItemComplete,
  completeOnFinish
}) {
  const dispatch = useDispatch();

  const [assessmentId, setAssessmentId] = useState(existingAssessmentId);

  const {
    question_ids: questionIds = [],
    questions = [],
    sections = [],
    sa_hide_skipped_questions: hideCondSkipped,
    hide_sa_previous: hidePrevious = false,
    ...instrument
  } = useSelector(state => state.instrument?.[instrumentId]?.object || {});

  const instrumentLoaded = instrument?.instrument_id === instrumentId;

  const skippedQIDs = useSelector(
    state => state.assessment?.[assessmentId]?.ruleEvaluation?.skippedQIDs || []
  );

  // questionIdMap
  // reduce over instrument section/page/item structure to create an object with questionId keys for lookup
  // {
  //   [questionId]: {
  //     sectionIndex: int
  //     pageIndex: int
  //     instances: int
  //     hidden: boolean // is_sa_hidden questions won't be shown
  //   }
  // }
  const questionIdMap = useMemo(
    () =>
      sections.reduce(
        (acc, { pages }, sectionIndex) => ({
          ...acc,
          ...pages.reduce(
            (acc, page, pageIndex) => ({
              ...acc,
              ...page.reduce((acc, item) => {
                if (
                  typeof item === "string" &&
                  item.indexOf("~text_only_section~") >= 0
                ) {
                  const questionId = item;
                  return {
                    ...acc,
                    [questionId]: {
                      sectionIndex,
                      pageIndex,
                      instances: 1,
                      hidden: false,
                      isRequired: false
                    }
                  };
                } else if (typeof item === "string") {
                  const questionId = item;
                  const {
                    max_instances: instances,
                    is_sa_hidden: hidden,
                    is_required: isRequired
                  } = questions[questionId];
                  return {
                    ...acc,
                    [questionId]: {
                      sectionIndex,
                      pageIndex,
                      instances,
                      hidden,
                      isRequired
                    }
                  };
                } else if (typeof item === "object") {
                  const {
                    question_type: type,
                    questions: questionsInObj
                  } = item;
                  if (type === "LIKERT_GRID") {
                    return {
                      ...acc,
                      ...questionsInObj.reduce((acc, questionId) => {
                        const {
                          max_instances: instances,
                          is_sa_hidden: hidden,
                          is_required: isRequired
                        } = questions[questionId];
                        return {
                          ...acc,
                          [questionId]: {
                            sectionIndex,
                            pageIndex,
                            instances,
                            hidden,
                            isRequired
                          }
                        };
                      }, {})
                    };
                  } else if (type === "TABLE") {
                    return {
                      ...acc,
                      ...questionsInObj.reduce(
                        (acc, row) => ({
                          ...acc,
                          ...row.reduce((acc, questionId) => {
                            const {
                              max_instances: instances,
                              is_sa_hidden: hidden,
                              is_required: isRequired
                            } = questions[questionId];
                            return {
                              ...acc,
                              [questionId]: {
                                sectionIndex,
                                pageIndex,
                                instances,
                                hidden,
                                isRequired,
                                isTableQuestion: true
                              }
                            };
                          }, {})
                        }),
                        {}
                      )
                    };
                  }
                }
                return acc;
              }, {})
            }),
            {}
          )
        }),
        {}
      ),
    [questions, sections]
  );

  // sectionPageMap
  // reduce over questionIdMap to create a nested array of sections, pages, and questionIds on the page
  const sectionPageMap = useMemo(
    () =>
      Object.entries(questionIdMap)
        .reduce((acc, [questionId, { sectionIndex, pageIndex, hidden }]) => {
          if (!hidden) {
            if (!acc[sectionIndex]) {
              acc[sectionIndex] = [];
            }
            if (!acc[sectionIndex][pageIndex]) {
              acc[sectionIndex][pageIndex] = [];
            }
            acc[sectionIndex][pageIndex].push(questionId);
          }
          return acc;
        }, [])
        .map(section =>
          section.map(page =>
            page.sort((a, b) => questionIds.indexOf(a) - questionIds.indexOf(b))
          )
        ),
    [questionIdMap, questionIds]
  );

  // questionPageMap
  // flatten sectionPageMap's nested section/page structure to an array of pages with page skip status
  const questionPageMap = useMemo(
    () =>
      sectionPageMap.reduce(
        (acc, pages, sectionIndex) =>
          [
            ...acc,
            ...pages.map(
              (questionIds, pageIndex) => ({
                sectionIndex,
                pageIndex,
                questionIdsOnPage: questionIds,
                allSkipped: questionIds.every(questionId =>
                  skippedQIDs.includes(questionId)
                )
              }),
              {}
            )
          ].filter(Boolean),
        []
      ),
    [sectionPageMap, skippedQIDs]
  );

  const firstPage = useMemo(
    () =>
      hideCondSkipped
        ? questionPageMap.find(page => page && !page.allSkipped)
        : questionPageMap.find(page => page),
    [hideCondSkipped, questionPageMap]
  );

  const [initCalled, setInitCalled] = useState(false);
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showErrors, setShowErrors] = useState(false);
  const [currentSection, setCurrentSection] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);

  const questionIdsOnPage = useMemo(() => {
    return sectionPageMap?.[currentSection]?.[currentPage] || [];
  }, [sectionPageMap, currentSection, currentPage]);

  // complete assessment with retry when response saving is pending
  const completeAssessmentWithRetry = useCallback(
    ({ assessmentId, queueId, maxRetry = 3 }) => {
      setIsLoading(true);
      return new Promise((resolve, reject) => {
        let count = 0;
        let inProgress = false;
        let intervalID = setInterval(() => {
          if (!inProgress) {
            inProgress = true;
            dispatch(
              completeAssessment({
                queueId,
                assessmentId,
                noCheck: Boolean(count >= maxRetry)
              })
            ).then(({ error }) => {
              if (!error) {
                clearInterval(intervalID);
                resolve();
              } else if (count >= maxRetry) {
                reject("Error saving the assessment.");
              } else {
                count = count + 1;
                inProgress = false;
              }
            });
          }
        }, 1000);
      });
    },
    [dispatch]
  );

  // create question refs to be passed down to each question rendered on the page
  const questionIdRefs = questionIdsOnPage.reduce((acc, questionId) => {
    const { instances } = questionIdMap?.[questionId] || 0;
    const questionRefs = arrayFrom(instances).reduce((acc, instance) => {
      acc[`${questionId}-${instance}`] = createRef();
      return acc;
    }, {});
    return { ...acc, ...questionRefs };
  }, {});

  // check if we need to start the assessment somewhere other then the beggining (resuming an assessment or SA preview)
  const [jumpToQID, setJumpToQID] = useState(startAtQuestion);

  // init assessment
  useEffect(() => {
    if (!initCalled && instrumentLoaded) {
      setInitCalled(true);
      const saPreview = assessmentId === -1;
      if (saPreview) {
        setIsLoading(false);
      } else {
        dispatch(
          initAssessment({
            queueId,
            queueInstrumentId,
            instrumentId,
            assessmentId,
            startAtQuestion
          })
        ).then(({ payload }) => {
          if (payload.error) {
            setError(payload.error);
            return;
          }

          const { assessmentId, startAtQuestion } = payload;

          setAssessmentId(assessmentId);

          if (startAtQuestion?.questionId === "END") {
            if (completeOnFinish) {
              completeAssessmentWithRetry({ assessmentId, queueId }).then(() =>
                handleItemComplete({ noPrompt: true })
              );
            } else {
              handleItemComplete({ noPrompt: true });
            }
          } else {
            setJumpToQID(startAtQuestion);
            setIsLoading(false);
          }
        });
      }
    }
  }, [
    dispatch,
    initCalled,
    instrumentLoaded,
    queueId,
    queueInstrumentId,
    instrumentId,
    assessmentId,
    startAtQuestion,
    completeOnFinish,
    handleItemComplete,
    completeAssessmentWithRetry
  ]);

  // change section and page for jump
  useEffect(() => {
    if (jumpToQID) {
      const { questionId } = jumpToQID;
      if (Object.keys(questionIdMap).includes(questionId)) {
        const { sectionIndex, pageIndex } = questionIdMap[questionId];
        if (currentSection !== sectionIndex) {
          setCurrentSection(sectionIndex);
        }
        if (currentPage !== pageIndex) {
          setCurrentPage(pageIndex);
        }
      }
    }
  }, [jumpToQID, questionIdMap, currentSection, currentPage]);

  // scroll to question after it's been rendered on the page
  useEffect(() => {
    if (jumpToQID) {
      const { questionId, instance } = jumpToQID;
      const questionIdInstance = `${questionId}-${instance}`;
      const questionIndex = questionIdsOnPage.indexOf(questionId);

      if (questionIndex === 0) {
        window.scrollTo({ top: 0 });
        setJumpToQID(null);
      } else if (
        questionIndex >= 1 &&
        questionIdRefs?.[questionIdInstance]?.current
      ) {
        questionIdRefs[questionIdInstance].current.scrollIntoView({
          behavior: "smooth",
          block: "center"
        });
        setJumpToQID(null);
      }
    }
  }, [jumpToQID, questionIdsOnPage, questionIdRefs]);

  function nextPage() {
    dispatch(
      evaluateMissingResponses({
        assessmentId,
        questionIds: questionIdsOnPage
      })
    ).then(({ payload: missingResponses }) => {
      if (
        missingResponses.filter(qid => {
          const question = questionIdMap[qid];
          return !question.isTableQuestion || question.isRequired;
        }).length !== 0 &&
        !(
          sections[currentSection].timerEndsAt &&
          Date.now() > sections[currentSection].timerEndsAt
        )
      ) {
        setShowErrors(true);
        setJumpToQID({ questionId: missingResponses[0], instance: 1 });
      } else {
        setShowErrors(false);

        const page =
          Date.now() > sections[currentSection].timerEndsAt
            ? questionPageMap.find(
                ({ sectionIndex, pageIndex, allSkipped }) =>
                  sectionIndex > currentSection
              )
            : questionPageMap.find(
                ({ sectionIndex, pageIndex, allSkipped }) =>
                  (!allSkipped || !hideCondSkipped) &&
                  (sectionIndex > currentSection ||
                    (sectionIndex === currentSection &&
                      pageIndex > currentPage))
              );

        if (page) {
          const { sectionIndex, pageIndex } = page;
          setCurrentSection(sectionIndex);
          setCurrentPage(pageIndex);
          window.scrollTo({ top: 0 });
        } else {
          if (completeOnFinish) {
            completeAssessmentWithRetry({
              assessmentId,
              queueId
            }).then(() => handleItemComplete());
          } else {
            handleItemComplete();
          }
        }
      }
    });
  }

  function previousPage() {
    const page =
      sections[currentSection - 1] &&
      sections[currentSection - 1].timerEndsAt &&
      Date.now() > sections[currentSection].timerEndsAt
        ? [...questionPageMap]
            .reverse()
            .find(
              ({ sectionIndex, pageIndex }) =>
                sectionIndex < currentSection && pageIndex === 0
            )
        : [...questionPageMap]
            .reverse()
            .find(
              ({ sectionIndex, pageIndex, allSkipped }) =>
                (!allSkipped || !hideCondSkipped) &&
                (sectionIndex < currentSection ||
                  (sectionIndex === currentSection && pageIndex < currentPage))
            );

    if (page) {
      const { sectionIndex, pageIndex } = page;
      setCurrentSection(sectionIndex);
      setCurrentPage(pageIndex);
      window.scrollTo({ top: 0 });
    }
  }

  // items to render on page
  const { pages } = sections?.[currentSection] || [];
  const section = sections?.[currentSection] || {};
  const page = pages?.[currentPage] || [];
  const isFirstPage =
    firstPage?.sectionIndex === currentSection &&
    firstPage?.pageIndex === currentPage;
  const progress = {
    currentSection,
    currentPage,
    progress: sectionPageMap.map((section, index) => ({
      label: sections[index].sa_label,
      pages: section
    }))
  };

  return {
    error,
    isLoading,
    showErrors,
    assessmentId,
    instrumentId,
    instrument,
    progress,
    section,
    page,
    hideCondSkipped,
    skippedQIDs,
    questions,
    questionIdRefs,
    isFirstPage,
    nextPage,
    showPreviousPage: !hidePrevious,
    previousPage
  };
}
