// temporary solution for easy testing purposes
import background1 from "../assets/background/IMG_1557212494339.jpg";
import background2 from "../assets/background/IMG_20190720_200726.jpg";
import background3 from "../assets/background/IMG_20190721_095743.jpg";
import background4 from "../assets/background/IMG_20190725_105158.jpg";
import background5 from "../assets/background/MVIMG_20190721_095858.jpg";
import background6 from "../assets/background/IMG_1557425409568.jpg";
import background7 from "../assets/background/IMG_20190720_200734.jpg";
import background8 from "../assets/background/IMG_20190721_111612.jpg";
import background9 from "../assets/background/IMG_20191221_164757.jpg";
import background10 from "../assets/background/IMG_20170818_173814.jpg";
import background11 from "../assets/background/IMG_20190720_200742.jpg";
import background12 from "../assets/background/IMG_20190722_141439.jpg";
import background13 from "../assets/background/MVIMG_20190506_081223.jpg";
import background14 from "../assets/background/IMG_20190508_161259.jpg";
import background15 from "../assets/background/IMG_20190720_200744.jpg";
import background16 from "../assets/background/IMG_20190723_161141.jpg";
import background17 from "../assets/background/MVIMG_20190507_185613.jpg";

const images = [
  background1,
  background2,
  background3,
  background4,
  background5,
  background6,
  background7,
  background8,
  background9,
  background10,
  background11,
  background12,
  background13,
  background14,
  background15,
  background16,
  background17
];

export function getRandomImage() {
  return images[Math.floor(Math.random() * images.length)];
}
