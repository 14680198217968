import React, { useState, useRef } from "react";
import { Link as RouterLink, useParams } from "react-router-dom";
import {
  Avatar,
  CssBaseline,
  TextField,
  Grid,
  Typography,
  Container,
  Link
} from "@material-ui/core";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import Alert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import SubmitButton from "./SubmitButton";
import doApiRequest from "../ApiClient";
import Captcha from "../Components/Captcha";

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3)
  }
}));

export default function RequestReset() {
  const classes = useStyles();
  const { stype } = useParams();

  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const captchaToken = useRef();

  async function handleSubmit(event) {
    event.preventDefault();
    setLoading(true);
    const { error, data } = await doApiRequest(
      `pas/reset?email=${email}&captchatoken=${captchaToken.current.value}${
        stype ? `&stype=${stype}` : ""
      }`,
      {
        method: "GET"
      }
    );
    setLoading(false);
    setError(error ? data?.errors : false);
    setEmailSent(!error);
  }

  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOpenIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Reset Password
        </Typography>
        <form className={classes.form} noValidate onSubmit={handleSubmit}>
          {!emailSent ? (
            <>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    onChange={e => setEmail(e.target.value)}
                  />
                </Grid>
              </Grid>
              <SubmitButton loading={loading}>Reset</SubmitButton>
            </>
          ) : (
            <Alert severity="success" style={{ marginBottom: "10px" }}>
              Password reset email sent to {email}. Please check your email to
              complete the process.
            </Alert>
          )}
          <Grid container justify="flex-end" style={{ marginBottom: "10px" }}>
            <Grid item>
              <Link
                component={RouterLink}
                to={"/login" + (stype ? `/${stype}` : "")}
                variant="body2"
              >
                Back to Login
              </Link>
            </Grid>
          </Grid>
          {error ? <Alert severity="error">{error}</Alert> : null}
          {!loading && <Captcha inputRef={captchaToken} />}
        </form>
      </div>
    </Container>
  );
}
