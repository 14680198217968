import React, { forwardRef } from "react";
import { makeStyles } from "@material-ui/core";
import * as yup from "yup";

import TextResponseDate from "./TextResponseDate";
import TextResponseDefault from "./TextResponseDefault";
import TextResponsePhone from "./TextResponsePhone";
import TextResponseTime from "./TextResponseTime";

const string = yup.string();

const number = yup
  .number()
  .transform((value, originalValue) => (originalValue === "" ? null : value))
  .nullable()
  .typeError("Please enter a number")
  .when("$min", (min, schema) =>
    min
      ? schema.min(min, `Please enter a number greater than or equal to ${min}`)
      : schema
  )
  .when("$max", (max, schema) =>
    max
      ? schema.max(max, `Please enter a number less than or equal to ${max}`)
      : schema
  );

const email = yup.string().email("Please enter a valid email");

const age = yup
  .number()
  .transform((value, originalValue) => (originalValue === "" ? null : value))
  .nullable()
  .min(0, "Please enter a positive number")
  .integer("Please enter a whole number")
  .typeError("Please enter a number");

const useStyles = makeStyles(theme => ({
  root: {
    padding: "0px 20px",
    textAlign: "left"
  }
}));

function TextResponse(props, ref) {
  const classes = useStyles();

  const inTable = props.parentType === "TABLE";

  return (
    <div className={`${!inTable && classes.root}`}>
      {{
        100: (
          <TextResponseDefault
            ref={ref}
            {...props}
            validation={{
              schema: number,
              context: {
                ...(props.numeric_response_max && {
                  max: props.numeric_response_max
                }),
                ...(props.numeric_response_min && {
                  min: props.numeric_response_min
                })
              }
            }}
            fullWidth
          />
        ),
        120: (
          <TextResponseDefault
            ref={ref}
            {...props}
            validation={{ schema: email }}
          />
        ),
        80: (
          <TextResponseDefault
            ref={ref}
            {...props}
            validation={{ schema: age }}
          />
        ),
        40: <TextResponsePhone ref={ref} {...props} />,
        20: (
          <TextResponseDate
            ref={ref}
            {...props}
            format={"MM/DD/YYYY"}
            views={["year", "month", "date"]}
          />
        ),
        21: (
          <TextResponseDate
            ref={ref}
            {...props}
            format={"MM/YYYY"}
            views={["year", "month"]}
          />
        ),
        70: (
          <TextResponseTime
            ref={ref}
            {...props}
            format={"hh:mm a"}
            views={["hours", "minutes"]}
          />
        ),
        60: (
          <TextResponseTime
            ref={ref}
            {...props}
            format={"hh:mm:ss a"}
            views={["hours", "minutes", "seconds"]}
          />
        )
      }[props?.response_format_id] || (
        <TextResponseDefault
          ref={ref}
          {...props}
          validation={{ schema: string }}
          fullWidth
          multiline
        />
      )}
    </div>
  );
}

export default forwardRef(TextResponse);
