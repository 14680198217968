import React from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import {
  Divider,
  Drawer,
  Hidden,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Badge
} from "@material-ui/core";
import {
  Home as HomeIcon,
  Inbox as InboxIcon,
  //  CalendarToday as CalendarTodayIcon,
  MonetizationOn as MonetizationOnIcon,
  Description as DescriptionIcon,
  Toc as StudyIcon,
  Email as EmailIcon
} from "@material-ui/icons";
import { makeStyles, useTheme } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex"
  },
  drawer: {
    [theme.breakpoints.up("md")]: {
      width: theme.drawerWidth,
      flexShrink: 0
    }
  },
  appBar: {
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${theme.drawerWidth}px)`,
      marginLeft: theme.drawerWidth
    }
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      display: "none"
    }
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: theme.drawerWidth
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3)
  }
}));

function DrawerLink({ label, to, icon: LinkIcon, badgeCount }) {
  return (
    <ListItem
      button
      key={label}
      component={NavLink}
      to={to}
      activeClassName="Mui-selected"
      exact
    >
      <ListItemIcon>
        <Badge badgeContent={badgeCount} color="secondary">
          <LinkIcon />
        </Badge>
      </ListItemIcon>
      <ListItemText primary={label} />
    </ListItem>
  );
}

export default function AppNavDrawer({ drawerOpen, handleDrawerToggle }) {
  const classes = useStyles();
  const theme = useTheme();
  const queueCount = useSelector(
    state =>
      Object.entries(state.queue.allQueues).filter(
        ([, { isComplete, isAvailable }]) => isAvailable && !isComplete
      ).length
  );

  const drawerContent = (
    <div id="left-nav">
      <div className={classes.toolbar} />
      <Divider />
      <List>
        <DrawerLink label="Home" to="/" icon={HomeIcon} />
        <DrawerLink label="Studies" to="/studies" icon={StudyIcon} />
        <DrawerLink
          label="Queues"
          to="/queues"
          icon={InboxIcon}
          badgeCount={queueCount}
        />
        {/* <DrawerLink label="Calendar" to="/calendar" icon={CalendarTodayIcon} /> */}
        <DrawerLink label="Payments" to="/payments" icon={MonetizationOnIcon} />
        <DrawerLink
          label="Radiology Letters"
          to="/radiology"
          icon={DescriptionIcon}
        />
        <DrawerLink label="Change Email" to="/change-email" icon={EmailIcon} />
      </List>
    </div>
  );

  return (
    <nav className={classes.drawer} aria-label="mailbox folders">
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Hidden mdUp implementation="css">
        <Drawer
          // container={container}
          variant="temporary"
          anchor={theme.direction === "rtl" ? "right" : "left"}
          open={drawerOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper
          }}
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
        >
          {drawerContent}
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          classes={{
            paper: classes.drawerPaper
          }}
          variant="permanent"
          open
        >
          {drawerContent}
        </Drawer>
      </Hidden>
    </nav>
  );
}
