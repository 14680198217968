import React from "react";
import { Typography } from "@material-ui/core";

export default function DangerousTypography({ children, ...props }) {
  return (
    <Typography
      {...{
        ...props,
        dangerouslySetInnerHTML: { __html: children || "" }
      }}
    />
  );
}
