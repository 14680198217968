import { useContext, useCallback } from "react";
import { useSelector } from "react-redux";
import { AssessmentContext } from "../../AppContext/AssessmentContext";
import {
  saveResponse,
  toggleUserSkipped
} from "../../reducers/assessmentSlice";

export default function useQuestionStateHook(props) {
  const { assessmentId } = useContext(AssessmentContext);
  const {
    question_id: questionId,
    loopInstance = 0,
    instance: instanceId = 1
  } = props;
  const response = useSelector(
    state =>
      state.assessment?.[assessmentId]?.responses?.[questionId]?.[loopInstance]
        ?.instances?.[instanceId]
  );
  const userSkipped = useSelector(
    state =>
      state.assessment?.[assessmentId]?.responses?.[questionId]?.[loopInstance]
        ?.user_skipped || false
  );

  const conditionallySkipped = useSelector(
    state =>
      state.assessment?.[assessmentId]?.responses?.[questionId]?.[loopInstance]
        ?.conditionally_skipped || false
  );

  const saveResponseWrapper = useCallback(
    response => {
      // save a null response object if the user clears out a response
      const responseValue =
        "value" in response &&
        (response?.value === "" || response?.value === null)
          ? {}
          : response;

      return saveResponse({
        assessmentId,
        questionId,
        loopInstance,
        instanceId,
        response: responseValue
      });
    },
    [assessmentId, questionId, loopInstance, instanceId]
  );

  const toggleUserSkippedWrapper = useCallback(
    () => toggleUserSkipped({ assessmentId, questionId, loopInstance }),
    [assessmentId, questionId, loopInstance]
  );

  const isValidResponse =
    Object.keys(response || {}).length >= 1 ||
    conditionallySkipped ||
    userSkipped;

  return {
    isValidResponse,
    response,
    conditionallySkipped,
    userSkipped,
    saveResponse: saveResponseWrapper,
    toggleUserSkipped: toggleUserSkippedWrapper
  };
}
