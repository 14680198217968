import React, { useState } from "react";
import { Link as RouterLink, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Avatar,
  CssBaseline,
  Link,
  Grid,
  Container,
  Paper,
  Typography,
  useTheme,
  useMediaQuery,
  Snackbar
} from "@material-ui/core";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";

import logo from "../assets/logo.svg";
import { getRandomImage } from "./randomImage";
import LoginForm from "./LoginForm";
import useStypeData from "../Registration/useStypeData";

const useStyles = makeStyles(theme => ({
  root: {
    height: "100vh"
  },
  image: {
    backgroundImage: `url(${getRandomImage()})`,
    backgroundRepeat: "no-repeat",
    backgroundColor: theme.viewBackgroundColor,
    backgroundSize: "cover",
    backgroundPosition: "center"
  },
  imageTextContainer: {
    marginTop: "50px",
    textAlign: "center"
  },
  imageText: {
    fontSize: "2vw",
    fontWeight: "bold"
  },
  logo: {
    height: "1vw",
    pointerEvents: "none"
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  error: { marginTop: theme.spacing(1) }
}));

export default function Login() {
  const classes = useStyles();
  const theme = useTheme();
  const minimalView = useMediaQuery(theme.breakpoints.down("xs"));

  const { code, stype } = useParams();
  const stypeData = useStypeData(stype);
  const isConfirm = Boolean(code);

  const { error } = useSelector(state => state?.session);
  const [showError, setShowError] = useState(true);

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image}>
        {!minimalView && (
          <Container className={classes.imageTextContainer}>
            <img src={logo} className={classes.logo} alt="logo" />
            <Typography
              variant="h4"
              color="primary"
              className={classes.imageText}
            >
              Participant Portal
            </Typography>
          </Container>
        )}
      </Grid>
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5" gutterBottom>
            {isConfirm
              ? "Sign in to confirm your account:"
              : stypeData?.label
              ? stypeData?.label
              : "Sign in to your account"}
          </Typography>
          {!isConfirm && stypeData?.label && (
            <Typography>
              To enroll in this study, sign in with your existing account and
              proceed to the enrollment form.
            </Typography>
          )}
          <LoginForm />
          <Grid container>
            <Grid item xs>
              <Link
                component={RouterLink}
                to={"/request-reset" + (stype ? `/${stype}` : "")}
                variant="body2"
              >
                Forgot password?
              </Link>
            </Grid>
            {!isConfirm && (
              <Grid item>
                <Link
                  component={RouterLink}
                  to={"/signup" + (stype ? `/${stype}` : "")}
                  variant="body2"
                >
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            )}
          </Grid>
        </div>
      </Grid>
      <div>
        {error && (
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center"
            }}
            open={showError}
          >
            <MuiAlert
              elevation={6}
              variant="filled"
              onClose={() => setShowError(false)}
              severity="info"
            >
              {error}
            </MuiAlert>
          </Snackbar>
        )}
      </div>
    </Grid>
  );
}
