import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  makeStyles,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography
} from "@material-ui/core";
import { Alert, Skeleton } from "@material-ui/lab";
import { useHistory } from "react-router";
import * as yup from "yup";

import doApiRequest from "../ApiClient";
import SubmitButton from "../Components/SubmitButton";
import { addQueue } from "../reducers/queueSlice";
import { useDispatch } from "react-redux";

const useStyles = makeStyles(theme => ({
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  header: {
    textAlign: "left",
    fontWeight: "bold",
    marginTop: theme.spacing(2)
  },
  form: {
    maxWidth: "600px"
  },
  input: {
    width: "100%"
  }
}));

export default function EnrollSubject({ stype, stypeData }) {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState(false);

  let enrollSchema = yup.object().shape({
    racial_categories: yup.array().notRequired(),
    ethnicity: yup.string().default("")
  });

  if (stypeData.showFutureContact) {
    enrollSchema = enrollSchema.concat(
      yup.object().shape({
        agrees_to_future_studies: yup
          .string()
          .required("Please choose yes or no")
      })
    );
  }

  if (stypeData.showDataSharing) {
    enrollSchema = enrollSchema.concat(
      yup.object().shape({
        agrees_to_share_data: yup.string().required("Please choose yes or no")
      })
    );
  }

  const { register, handleSubmit, errors } = useForm({
    validationSchema: enrollSchema
  });

  const submit = values => {
    setLoading(true);

    doApiRequest("pas/enroll/", {
      method: "POST",
      body: JSON.stringify({ ...values, subject_type_id: stype })
    }).then(result => {
      if (result.error) {
        setApiError(result?.data?.errors[0] || "Unknown error");
        setLoading(false);
      } else {
        if (result?.data.queue_id) {
          dispatch(addQueue(result.data.queue_id));
          history.push(`/queues/${result.data.queue_id}`);
        } else {
          history.push("/");
        }
      }
    });
  };

  return (
    <div className={classes.paper}>
      <form onSubmit={handleSubmit(submit)} className={classes.form}>
        <Grid container spacing={4} justify="center">
          <Grid item xs={12}>
            <Typography className={classes.header}>
              {stypeData?.label}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.header}>
              Additional Information:
            </Typography>
          </Grid>
          {stypeData.raceAndEthnicity && (
            <>
              <Grid item xs={12}>
                <RaceInput
                  inputRef={register}
                  error={errors?.racial_categories}
                />
              </Grid>
              <Grid item xs={12}>
                <EthnicityInput inputRef={register} error={errors?.ethnicity} />
              </Grid>
            </>
          )}
          {stypeData?.showFutureContact && (
            <Grid item xs={12}>
              <FutureStudiesInput
                inputRef={register}
                error={errors?.agrees_to_future_studies}
              />
            </Grid>
          )}
          {stypeData?.showDataSharing && (
            <Grid item xs={12}>
              <ShareDataInput
                inputRef={register}
                error={errors?.agrees_to_share_data}
              />
            </Grid>
          )}
        </Grid>
        <br />
        <SubmitButton loading={loading} buttonText="Enroll" />
      </form>
      <br />
      {errors?.message && <Alert severity="error">{errors.message}</Alert>}
      {apiError && <Alert severity="error">{apiError}</Alert>}
    </div>
  );
}

function RaceInput({ inputRef, error }) {
  const [racialCategories, setRacialCategories] = useState(false);
  useEffect(() => {
    doApiRequest("pas/racial-categories/").then(({ data }) => {
      setRacialCategories(data);
    });
  }, []);

  return (
    <FormControl error={error ? true : false} style={{ width: "100%" }}>
      <FormLabel component="legend">Race</FormLabel>
      <FormGroup name="gender">
        {racialCategories ? (
          Object.keys(racialCategories).map(race => {
            return (
              <FormControlLabel
                key={race}
                control={
                  <Checkbox
                    name="racial_categories"
                    value={racialCategories[race]}
                    color="primary"
                    inputRef={inputRef}
                  />
                }
                label={
                  race === "Unknown/Not Reported"
                    ? "Prefer not to answer"
                    : race
                }
              />
            );
          })
        ) : (
          <>
            <Skeleton variant="text" />
            <Skeleton variant="text" />
            <Skeleton variant="text" />
          </>
        )}
      </FormGroup>
      {error && <FormHelperText>{error.message}</FormHelperText>}
    </FormControl>
  );
}

function EthnicityInput({ inputRef, error }) {
  const [ethnicCategories, setEthnicCategories] = useState(false);
  useEffect(() => {
    doApiRequest("pas/ethnic-categories/").then(({ data }) => {
      setEthnicCategories(data);
    });
  }, []);

  return (
    <FormControl error={error ? true : false} style={{ width: "100%" }}>
      <FormLabel component="legend">Ethnicity</FormLabel>
      <RadioGroup name="ethnicity">
        {ethnicCategories ? (
          Object.keys(ethnicCategories).map(ethnicity => {
            return (
              <FormControlLabel
                key={ethnicity}
                control={
                  <Radio
                    name="ethnicity"
                    value={ethnicCategories[ethnicity]}
                    color="primary"
                    inputRef={inputRef}
                  />
                }
                label={
                  ethnicity === "Unknown/Not Reported"
                    ? "Prefer not to answer"
                    : ethnicity
                }
              />
            );
          })
        ) : (
          <>
            <Skeleton variant="text" />
            <Skeleton variant="text" />
            <Skeleton variant="text" />
          </>
        )}
      </RadioGroup>
      {error && <FormHelperText>{error.message}</FormHelperText>}
    </FormControl>
  );
}

function FutureStudiesInput({ inputRef, error }) {
  return (
    <FormControl error={error ? true : false}>
      <RadioGroup name="agrees_to_future_studies">
        <FormLabel component="legend">
          Do you agree to be contacted for future studies?
        </FormLabel>

        <FormControlLabel
          control={
            <Radio
              name="agrees_to_future_studies"
              color="primary"
              value="Y"
              inputRef={inputRef}
            />
          }
          label="Yes"
        />
        <FormControlLabel
          control={
            <Radio
              name="agrees_to_future_studies"
              color="primary"
              value="N"
              inputRef={inputRef}
            />
          }
          label="No"
        />
      </RadioGroup>
      {error && <FormHelperText>{error.message}</FormHelperText>}
    </FormControl>
  );
}

function ShareDataInput({ inputRef, error }) {
  return (
    <FormControl error={error ? true : false}>
      <RadioGroup name="agrees_to_share_data">
        <FormLabel component="legend" error={error ? true : false}>
          Do you agree to share your data?
        </FormLabel>
        <FormControlLabel
          control={
            <Radio
              name="agrees_to_share_data"
              color="primary"
              value="Y"
              inputRef={inputRef}
            />
          }
          label="Yes"
        />
        <FormControlLabel
          control={
            <Radio
              name="agrees_to_share_data"
              color="primary"
              value="N"
              inputRef={inputRef}
            />
          }
          label="No"
        />
        {error && <FormHelperText>{error.message}</FormHelperText>}
      </RadioGroup>
    </FormControl>
  );
}
