import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import doApiRequest from "../ApiClient";

const authCookieName = "sessionid";

export function checkAuth() {
  return (
    document.cookie
      .split(";")
      .filter(item => item.trim().startsWith(`${authCookieName}=`)).length >= 1
  );
}

function deleteCookie() {
  document.cookie = `$authCookieName=; expires=Thu, 01 Jan 1970 00:00:00 GMT`;
}

export const logout = createAsyncThunk(
  "session/logout",
  async (payload, { rejectWithValue }) => {
    const result = await doApiRequest("pas/logout/", {
      method: "POST"
    });

    if (result.error) {
      return rejectWithValue(result);
    }
    deleteCookie();

    return payload;
  }
);

const sessionSlice = createSlice({
  name: "session",
  initialState: {
    email: null,
    authenticated: checkAuth(), // we should have a server side auth check
    emailConfirmed: false,
    expire: null,
    dqCode: null
  },
  reducers: {
    login: (state, action) => {
      const { email, emailConfirmed } = action.payload;
      state.email = email;
      state.authenticated = true;
      state.emailConfirmed = emailConfirmed;
      state.dqCode = null;
    },
    setEmail: (state, action) => {
      state.email = action.payload;
    },
    setEmailConfirmed: (state, action) => {
      state.emailConfirmed = action.payload;
    },
    setSessionExpire: (state, action) => {
      state.expire = parseInt(action.payload);
    },
    setDqCode: (state, action) => {
      state.dqCode = action.payload;
    }
  },
  extraReducers: {
    [logout.fulfilled]: (state, action) => {
      state.email = null;
      state.emailConfirmed = false;
      state.authenticated = false;
      state.expire = null;
      state.error = action.payload;
    }
  }
});

export const {
  login,
  setEmailConfirmed,
  setEmail,
  setSessionExpire,
  setDqCode
} = sessionSlice.actions;

export default sessionSlice.reducer;
