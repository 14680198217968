import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { LinearProgress } from "@material-ui/core";

import doApiRequest from "../ApiClient";
import Queue from "./Queue";
import { setDqCode } from "../reducers/sessionSlice";
import Message from "../Components/Message";
import { addQueue } from "../reducers/queueSlice";

export default function DirectQueue() {
  const { code } = useParams();
  const [error, setError] = useState(false);
  const [queueId, setQueueId] = useState(null);
  const [queueLoaded, setQueueLoaded] = useState(false);
  const codeInState = useSelector(state => state.session?.dqCode);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!codeInState) {
      dispatch(setDqCode(code));
    }
  }, [code, codeInState, dispatch]);

  useEffect(() => {
    if (!queueId) {
      doApiRequest(`pas/dq-queue/${code}/`).then(({ error, data }) => {
        if (error) {
          setError(data.errors);
        } else {
          setQueueId(data);
        }
      });
    }
  }, [code, queueId, setQueueId, setError]);

  useEffect(() => {
    if (queueId && !queueLoaded) {
      dispatch(addQueue(queueId)).then(({ payload, error }) => {
        if (error) {
          setError(payload);
        } else {
          setQueueLoaded(true);
        }
      });
    }
  }, [queueId, queueLoaded, setQueueLoaded, setError, dispatch]);

  if (codeInState && queueId && queueLoaded) {
    return <Queue queueId={queueId} directQueue />;
  }

  if (error) {
    return <Message>{error}</Message>;
  }

  return <LinearProgress />;
}
