import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, CircularProgress } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    alignItems: "center"
  },
  wrapper: {
    position: "relative",
    width: "100%"
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  }
}));

export default function SubmitButton({
  loading,
  className: outerClassName,
  buttonText = "Sign In"
}) {
  const classes = useStyles();

  return (
    <div className={`${classes.root} ${outerClassName}`}>
      <div className={classes.wrapper}>
        <Button
          type="submit"
          color="primary"
          variant="contained"
          disabled={loading}
          fullWidth
        >
          {buttonText}
        </Button>
        {loading && (
          <CircularProgress size={24} className={classes.buttonProgress} />
        )}
      </div>
    </div>
  );
}
